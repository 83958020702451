import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import { copyCurrentNovel } from "../../plugin/tools";

export default function DetailLayout({ children }) {
  // router变量区
  const navigate = useNavigate();

  // redux变量区
  const dispatch = useDispatch();
  const novel = useSelector((state) => state.novel);

  const backToCitizens = () => {
    navigate("/citizens");
  };

  const editNovel = (novelId) => {
    navigate("/editor/" + novelId);
  };

  const copyNovel = async () => {
    const copyCurrentNovelResult = await copyCurrentNovel();
    if (copyCurrentNovelResult.status === "success") {
      editNovel(copyCurrentNovelResult.data);
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: copyCurrentNovelResult.message,
        })
      );
    }
  };

  return (
    <Box>
      <AppBar color="beige" position="fixed" elevation={0}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Tooltip placement="bottom" title="返回工作台">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                backToCitizens();
              }}
            >
              <Icon.ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h6"
            component={"div"}
            sx={{
              position: "absolute",
              zIndex: 1,
              left: "20%",
              right: "20%",
              textAlign: "center",
            }}
          >
            {novel.title}
          </Typography>
          <Tooltip placement="bottom" title="使用该模版创建文章">
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 1 }}
              onClick={() => {
                copyNovel();
              }}
            >
              <Icon.FileCopy />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: "#faf5ea", minHeight: "100vh" }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
