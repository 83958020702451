import axios from "axios";
import { store } from "../store";
import { removeToken } from "../store/slices/tokenSlice";

const unauthCode = "rbac-exception-03";

const config = {
  baseURL: "https://platogaze.com/project027",
  timeout: 60 * 1000,
  withCredentials: true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = store.getState().token.value;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    if (response.data.code === unauthCode) store.dispatch(removeToken());
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default _axios;
