import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import _axios from "../../plugin/axios";

export default function QuicknoteList() {
  // redux变量区
  const dispatch = useDispatch();

  // state变量区
  const [quicknoteList, setQuicknoteList] = useState([]);
  const [editingQuicknote, setEditingQuicknote] = useState({});
  const [showQuicknoteEditionFlag, setShowQuicknoteEditionFlag] =
    useState(false);

  const quicknoteListInit = () => {
    _axios
      .get("/quicknote/quicknote-list")
      .then((resp) => {
        if (resp.data.code === "00") {
          setQuicknoteList(resp.data.data);
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "error",
            message: error.message,
          })
        );
      });
  };

  const addQuicknote = () => {
    setShowQuicknoteEditionFlag(true);
  };

  const editQuicknote = (quicknote) => {
    setEditingQuicknote(quicknote);
    setShowQuicknoteEditionFlag(true);
  };

  const finishQuicknoteEdition = () => {
    setEditingQuicknote({});
    setShowQuicknoteEditionFlag(false);
  };

  useEffect(() => {
    quicknoteListInit();
  }, [finishQuicknoteEdition]);

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Tooltip placement="top" title="添加笔记">
        <Fab
          color="secondary"
          size="small"
          sx={{ position: "absolute", bottom: "12px", right: "12px" }}
          onClick={() => {
            addQuicknote();
          }}
        >
          <Icon.Add />
        </Fab>
      </Tooltip>
      {quicknoteList.map((quicknote, index) => (
        <QuickNoteCard
          key={index}
          quicknote={quicknote}
          editQuicknote={editQuicknote}
        />
      ))}
      {showQuicknoteEditionFlag && (
        <EditQuicknoteDialog
          dialogState={showQuicknoteEditionFlag}
          editingQuicknote={editingQuicknote}
          finishEdition={finishQuicknoteEdition}
        />
      )}
    </Container>
  );
}

function EditQuicknoteDialog({ dialogState, editingQuicknote, finishEdition }) {
  // redux变量区
  const dispatch = useDispatch();

  // state变量区
  const [content, setContent] = useState({
    value: editingQuicknote.quicknoteId ? editingQuicknote.content : "",
  });
  const [type, setType] = useState({
    value: editingQuicknote.quicknoteId ? editingQuicknote.type : "",
  });

  const fillContent = (value) => {
    if (value === "") {
      setContent({
        ...content,
        value,
        errorFlag: true,
        errorMessage: "笔记内容不能为空",
      });
    } else {
      setContent({
        ...content,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const fillType = (value) => {
    if (value === "") {
      setType({
        ...type,
        value,
        errorFlag: true,
        errorMessage: "笔记类型不能为空",
      });
    } else {
      setType({
        ...type,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const deleteQuicknote = () => {
    if (!editingQuicknote.quicknoteId) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "缺少quicknoteId，无法删除",
        })
      );
    } else {
      let deleteParams = {
        quicknoteId: editingQuicknote.quicknoteId,
      };
      _axios
        .delete("/quicknote/quicknote", { params: deleteParams })
        .then((resp) => {
          if (resp.data.code === "00") {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
            finishEdition();
          } else {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackbar({
              type: "success",
              message: error.message,
            })
          );
        });
    }
  };

  const finishQuicknoteEdition = () => {
    if (!editingQuicknote.quicknoteId) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "缺少quicknoteId，无法修改",
        })
      );
    } else {
      let putObj = {
        quicknoteId: editingQuicknote.quicknoteId,
        content: content.value,
        type: type.value,
      };
      _axios
        .put("/quicknote/quicknote", putObj)
        .then((resp) => {
          if (resp.data.code === "00") {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
            finishEdition();
          } else {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackbar({
              type: "success",
              message: error.message,
            })
          );
        });
    }
  };

  const finishQuicknoteAddition = () => {
    if (editingQuicknote.quicknoteId) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "存在quicknoteId，无法添加",
        })
      );
    } else {
      let postObj = {
        content: content.value,
        type: type.value,
      };
      _axios
        .post("/quicknote/quicknote", postObj)
        .then((resp) => {
          if (resp.data.code === "00") {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
            finishEdition();
          } else {
            dispatch(
              setSnackbar({
                type: "success",
                message: resp.data.message,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackbar({
              type: "success",
              message: error.message,
            })
          );
        });
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>我的笔记</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="笔记类型"
              value={type.value}
              variant="standard"
              margin="normal"
              fullWidth
              error={type.errorFlag}
              helperText={type.errorMessage}
              onInput={(event) => {
                fillType(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="笔记内容"
              value={content.value}
              variant="standard"
              margin="normal"
              fullWidth
              multiline
              rows={4}
              error={content.errorFlag}
              helperText={content.errorMessage}
              onInput={(event) => {
                fillContent(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      {editingQuicknote.quicknoteId && (
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              deleteQuicknote();
            }}
          >
            删除
          </Button>
          <Button
            disabled={
              content.value === "" ||
              content.errorFlag ||
              type.value === "" ||
              type.errorFlag
            }
            onClick={() => {
              finishQuicknoteEdition();
            }}
          >
            修改
          </Button>
        </DialogActions>
      )}
      {!editingQuicknote.quicknoteId && (
        <DialogActions>
          <Button
            disabled={
              content.value === "" ||
              content.errorFlag ||
              type.value === "" ||
              type.errorFlag
            }
            onClick={() => {
              finishQuicknoteAddition();
            }}
          >
            添加
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

function QuickNoteCard({ quicknote, editQuicknote }) {
  const labelColorGenerator = (string) => {
    let colorList = ["primary", "secondary", "error", "success", "warning"];
    let stringNum = 0;
    for (let index = 0; index < string.length; index++) {
      stringNum += parseInt(string[index].charCodeAt(0), 10);
    }
    let colorIndex = stringNum % colorList.length;
    return colorList[colorIndex];
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardActionArea
        onClick={() => {
          editQuicknote(quicknote);
        }}
      >
        <CardContent
          sx={{
            pb: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            {format(quicknote.lastModifiedTime, "y/MM/dd HH:mm")}
          </Typography>
          <Chip
            variant="outlined"
            size="small"
            color={labelColorGenerator(quicknote.type)}
            label={quicknote.type}
          ></Chip>
        </CardContent>
        <CardContent>
          <Typography variant="body2">{quicknote.content}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
