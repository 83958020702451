import { createSlice } from "@reduxjs/toolkit";

export const selectedAIProviderSlice = createSlice({
  name: "novel",
  initialState: {
    title: "讯飞大模型",
    value: "xunfei",
  },
  reducers: {
    setSelectedAIProvider: (state, action) => {
      state.title = action.payload.title;
      state.value = action.payload.value;
    },
  },
});

export const { setSelectedAIProvider } = selectedAIProviderSlice.actions;

export default selectedAIProviderSlice.reducer;
