import { useRef, useState, useEffect, useCallback } from 'react'
import Waterfall from '../frame/Waterfall'
import HorizontalNovelItem from '../frame/NovelItems/HorizontalNovelItem'
import _axios from '../../plugin/axios'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../plugin/store/slices/snackbarSlice'
export default function WaterfallNovel({}) {
  // redux变量区
  const dispatch = useDispatch()

  // state变量区
  const [list, setList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [loading, setLoading] = useState(false)
  const [stopLoad, setStopLoad] = useState(false)
  const listRef = useRef(list)
  const pageNumRef = useRef(pageNum)
  const loadingRef = useRef(loading)
  const stopLoadRef = useRef(stopLoad)

  const loadingItem = useState({})

  const scrollRef = useRef(null)

  const loadFirst = async () => {
    let getParams = {
      published: 1,
      hot: 1,
      pageSize: 4,
      page: 1,
    }
    await _axios
      .get('/novel/novel-list', { params: getParams })
      .then((resp) => {
        if (resp.data.code === '00') {
          setList(resp.data.data.novelInfoList)
        } else {
          dispatch(
            setSnackbar({
              type: 'error',
              message: resp.data.message,
            }),
          )
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: 'success',
            message: error.message,
          }),
        )
      })
  }

  const loadMore = async () => {
    let getParams = {
      published: 1,
      hot: 1,
      pageSize: 4,
      page: pageNumRef.current + 1,
    }
    setLoading(true)
    setList([...listRef.current, {}])
    await _axios
      .get('/novel/novel-list', { params: getParams })
      .then((resp) => {
        if (resp.data.code === '00') {
          if (listRef.current.length >= resp.data.data.totalCount) {
            setList(listRef.current.slice(0, -1))
            setStopLoad(true)
            setLoading(false)
          } else {
            setList([
              ...listRef.current.slice(0, -1),
              ...resp.data.data.novelInfoList,
            ])
            setPageNum(pageNumRef.current + 1)
            setLoading(false)
          }
        } else {
          dispatch(
            setSnackbar({
              type: 'error',
              message: resp.data.message,
            }),
          )
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: 'success',
            message: error.message,
          }),
        )
      })
  }

  const handleScroll = () => {
    if (loadingRef.current || stopLoadRef.current) return
    clearTimeout(handleScroll.timer)
    handleScroll.timer = setTimeout(() => {
      const scrollPosition = window.innerHeight + window.scrollY
      const pageHeight = document.documentElement.offsetHeight
      const bottomOffset = 200
      if (scrollPosition >= pageHeight - bottomOffset) {
        loadMore()
      }
    }, 200)
  }

  useEffect(() => {
    loadFirst()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    listRef.current = list
    pageNumRef.current = pageNum
    loadingRef.current = loading
    stopLoadRef.current = stopLoad
  }, [list, pageNum, loading, stopLoad])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        padding: '32px calc((100vw - 300px - 800px)/2)',
      }}
      ref={scrollRef}
    >
      <Waterfall
        scrollRef={scrollRef}
        cols={1}
        gap={10}
        itemList={list}
        itemRender={(item, i) => {
          return (
            <div key={i} style={{ padding: '8px' }}>
              <HorizontalNovelItem novel={item}></HorizontalNovelItem>
            </div>
          )
        }}
      />
    </div>
  )
}
