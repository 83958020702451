import { createSlice } from '@reduxjs/toolkit'

export const routesListSlice = createSlice({
  name: 'routesList',
  initialState: {
    value: [],
  },
  reducers: {
    setRoutesList: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setRoutesList } = routesListSlice.actions

export default routesListSlice.reducer
