import { useScrollTrigger } from '@mui/material'
import { cloneElement } from 'react'

export default function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(children, {
    elevation: trigger ? 6 : 0,
  })
}
