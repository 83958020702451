import { FiberManualRecord, PlayArrow } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import inlineFuncColorOptions from "../../plugin/tools/inlineFuncColorOptions";

export default function InlineFuncSet({
  isComputing,
  updateEntity,
  selectInlineFunc,
  computeInlineFunc,
}) {
  // redux变量区
  const novel = useSelector((state) => state.novel);

  // state变量区
  const [inlineFuncSet, setInlineFuncSet] = useState([]);

  const inlineFuncSetInit = () => {
    if (novel.novelId) {
      let existedInlineFuncSet = [];
      Object.values(novel.draftRaw.entityMap).forEach((entity) => {
        const inlineFuncItem = {
          uuid: entity.data.uuid,
          inlineFunc: entity.data.inlineFunc,
          globalSet: entity.data.globalSet,
          color: entity.data.color,
        };
        existedInlineFuncSet.push(inlineFuncItem);
      });
      setInlineFuncSet(existedInlineFuncSet);
    }
  };

  useEffect(() => {
    inlineFuncSetInit();
  }, [novel.draftRaw]);

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {inlineFuncSet.map((inlineFuncSetItem, index) => (
        <InlineFuncSetItemCard
          key={index}
          inlineFuncSetItem={inlineFuncSetItem}
          isComputing={isComputing}
          updateEntity={updateEntity}
          selectInlineFunc={selectInlineFunc}
          computeInlineFunc={computeInlineFunc}
        />
      ))}
    </Container>
  );
}

const InlineFuncSetItemCard = ({
  inlineFuncSetItem,
  isComputing,
  updateEntity,
  selectInlineFunc,
  computeInlineFunc,
}) => {
  // redux变量区
  const globalSet = useSelector((state) => state.novel).globalSet;

  // state变量区
  const [anchorEl, setAnchorEl] = useState(null);

  const openColorMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeColor = (color) => {
    const newData = { ...inlineFuncSetItem, color: color };
    updateEntity(inlineFuncSetItem.uuid, newData);
    closeColorMenu();
  };

  const closeColorMenu = () => {
    setAnchorEl(null);
  };

  const changeGlobalSet = (globalValueId) => {
    const globalSet = inlineFuncSetItem.globalSet.includes(globalValueId)
      ? inlineFuncSetItem.globalSet.filter((selectedGlobalValueId) => {
          return selectedGlobalValueId !== globalValueId;
        })
      : [...inlineFuncSetItem.globalSet, globalValueId];
    const newData = { ...inlineFuncSetItem, globalSet: globalSet };
    updateEntity(inlineFuncSetItem.uuid, newData);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardActionArea
        onClick={() => {
          selectInlineFunc(inlineFuncSetItem.uuid);
        }}
      >
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            flex={1}
          >
            {inlineFuncSetItem.inlineFunc}
          </Typography>
          <Tooltip arrow placement="left" title="修改高亮">
            <IconButton
              size="small"
              disabled={isComputing}
              onClick={(event) => {
                openColorMenu(event);
              }}
            >
              <FiberManualRecord
                id="color-menu-button"
                aria-controls={anchorEl ? "color-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "color-menu" : undefined}
                fontSize="inherit"
                sx={{ color: inlineFuncSetItem.color }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            id="color-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              closeColorMenu();
            }}
            MenuListProps={{
              "aria-labelledby": "color-menu-button",
              sx: { maxWidth: "156px", px: 1 },
            }}
          >
            {inlineFuncColorOptions.map((inlineFuncColor, index) => (
              <IconButton
                key={index}
                size="small"
                onClick={() => {
                  changeColor(inlineFuncColor);
                }}
              >
                <FiberManualRecord
                  fontSize="inherit"
                  sx={{ color: inlineFuncColor }}
                />
              </IconButton>
            ))}
          </Menu>
          <Tooltip
            arrow
            placement="right"
            title={
              isComputing === inlineFuncSetItem.uuid ? "正在计算…" : "计算"
            }
          >
            <span>
              <IconButton
                size="small"
                disabled={isComputing}
                onClick={() => {
                  computeInlineFunc(inlineFuncSetItem.uuid);
                }}
              >
                {isComputing === inlineFuncSetItem.uuid ? (
                  <CircularProgress size={18} thickness={6} />
                ) : (
                  <PlayArrow fontSize="inherit" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </CardContent>
      </CardActionArea>
      <CardContent>
        <FormGroup row>
          {globalSet.map((globalValue, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox size="small" />}
              label={globalValue.name}
              checked={inlineFuncSetItem.globalSet.includes(globalValue.id)}
              onChange={() => {
                changeGlobalSet(globalValue.id);
              }}
            />
          ))}
        </FormGroup>
      </CardContent>
    </Card>
  );
};
