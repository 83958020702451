import { useEffect, useState } from "react";
import EditorLayout from "../components/layout/EditorLayout";
import { Backdrop, CircularProgress } from "@mui/material";
import { downloadNovel } from "../plugin/tools";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../plugin/store/slices/snackbarSlice";
import NovelContent from "../components/editor/NovelContent";

export default function EditorView() {
  // redux变量区
  const dispatch = useDispatch();

  // router变量区
  const currentNovelId = useParams().novelId;

  // state变量区
  const [runningTemplateFlag, setRunningTemplateFlag] = useState(false);

  const novelInit = async () => {
    const downloadNovelResult = await downloadNovel(currentNovelId);
    if (downloadNovelResult.status === "error") {
      dispatch(
        setSnackbar({
          type: "error",
          message: downloadNovelResult.message,
        })
      );
    }
  };

  useEffect(() => {
    novelInit();
  }, []);

  return (
    <EditorLayout>
      {runningTemplateFlag && (
        <Backdrop open={runningTemplateFlag}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <NovelContent />
    </EditorLayout>
  );
}
