import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateNovel } from "../../plugin/tools";
import AIProviderOptions from "../../plugin/tools/AIProviderOptions";
import { setSelectedAIProvider } from "../../plugin/store/slices/selectedAIProviderSlice";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import useInterval from "react-useinterval";

export default function EditorLayout({ children }) {
  // redux变量区
  const dispatch = useDispatch();
  const novel = useSelector((state) => state.novel);
  const selectedAIProvider = useSelector((state) => state.selectedAIProvider);

  // router变量区
  const navigate = useNavigate();

  // state变量区
  const [savingFlag, setSavingFlag] = useState(false);
  const [AIProviderAnchorEl, setAIProviderAnchorEl] = useState(null);
  const [showShortCutDialogFlag, setShowShortCutDialogFlag] = useState(false);

  const backToDesktop = async () => {
    await saveNovel();
    navigate("/desktop");
  };

  const saveNovel = async () => {
    setSavingFlag(true);
    const updateNovelResult = await updateNovel();
    if (updateNovelResult.status === "success") {
      dispatch(
        setSnackbar({
          type: "success",
          message: "文章保存成功！",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: updateNovelResult.message,
        })
      );
    }
    setSavingFlag(false);
  };

  const runTemplate = () => {};

  const openAIProviderMenu = (event) => {
    setAIProviderAnchorEl(event.currentTarget);
  };

  const closeAIProviderMenu = () => {
    setAIProviderAnchorEl(null);
  };

  const changeAIProvider = (AIProvider) => {
    dispatch(setSelectedAIProvider(AIProvider));
    closeAIProviderMenu();
  };

  const showShortCutDialog = () => {
    setShowShortCutDialogFlag(true);
  };

  const closeShortCutDialog = () => {
    setShowShortCutDialogFlag(false);
  };

  useInterval(() => {
    saveNovel();
  }, 60 * 1000);

  return (
    <Box>
      <AppBar color="beige" position="fixed" elevation={0}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <Tooltip placement="bottom" title="返回工作台">
              <IconButton
                edge="start"
                color="inherit"
                disabled={savingFlag}
                sx={{ mr: 0.5 }}
                onClick={() => {
                  backToDesktop();
                }}
              >
                <Icon.ArrowBack />
              </IconButton>
            </Tooltip>
            <Tooltip placement="bottom" title="保存文章">
              <IconButton
                edge="start"
                color="inherit"
                disabled={savingFlag}
                sx={{ mx: 0.5 }}
                onClick={() => {
                  saveNovel();
                }}
              >
                <Icon.Save />
              </IconButton>
            </Tooltip>
            {/* <Tooltip placement="bottom" title="运行模版">
              <IconButton
                edge="start"
                color="inherit"
                sx={{ mx: 0.5 }}
                onClick={() => {
                  runTemplate();
                }}
              >
                <Icon.PlayArrow />
              </IconButton>
            </Tooltip> */}
          </Box>
          <Typography
            variant="h6"
            component={"div"}
            sx={{
              position: "absolute",
              zIndex: 1,
              left: "20%",
              right: "20%",
              textAlign: "center",
            }}
          >
            {novel.title}
          </Typography>
          <Box>
            <Button
              id="ai-provider-menu-button"
              color="inherit"
              sx={{ mx: 0.5, textTransform: "none" }}
              startIcon={<Icon.Assistant />}
              endIcon={<Icon.ArrowDropDown />}
              aria-controls={
                AIProviderAnchorEl ? "ai-provider-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={
                AIProviderAnchorEl ? "ai-provider-menu" : undefined
              }
              onClick={(event) => {
                openAIProviderMenu(event);
              }}
            >
              {selectedAIProvider.title}
            </Button>
            <Menu
              id="ai-provider-menu"
              anchorEl={AIProviderAnchorEl}
              open={Boolean(AIProviderAnchorEl)}
              onClose={() => {
                closeAIProviderMenu();
              }}
              MenuListProps={{
                "aria-labelledby": "ai-provider-menu-button",
              }}
            >
              <MenuList dense>
                {AIProviderOptions.map((AIProviderOption, index) => (
                  <MenuItem
                    key={index}
                    disabled={AIProviderOption.banned}
                    onClick={() => {
                      changeAIProvider(AIProviderOption);
                    }}
                  >
                    {AIProviderOption.title}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Tooltip placement="bottom" title="查看保留字列表">
              <IconButton
                edge="end"
                color="inherit"
                sx={{ ml: 0.5 }}
                onClick={() => {
                  showShortCutDialog();
                }}
              >
                <Icon.HelpCenter />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Box bgcolor={"#faf5ea"} minHeight={"100vh"} flex={1}>
        <Toolbar />
        {children}
      </Box>
      <ShortCutDialog
        open={showShortCutDialogFlag}
        finishEdition={closeShortCutDialog}
      />
    </Box>
  );
}

function ShortCutDialog({ open, finishEdition }) {
  const [shortCutRows] = useState([
    { key: "\\f(...)", description: "行内AI" },
    { key: "\\funcname(...)", description: "助手" },
    { key: "[referenceName]", description: "引用" },
    { key: "\\pc()", description: "前文（最多600个字符）" },
  ]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>
        保留字列表
        <IconButton
          color="inherit"
          onClick={() => {
            finishEdition();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Icon.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            {shortCutRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.key}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
