import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    black: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    grey: {
      main: '#9e9e9e',
      contrastText: '#000000',
    },
    beige: {
      main: '#faf5ea',
      contrastText: '#000000',
    },
    cover: {
      main: '#ebd9b4',
      contrastText: '#000000',
    },
    primary: {
      main: '#335737',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#666D4E',
      contrastText: '#ffffff',
    },
    error: {
      main: '#a75051',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196F3',
      contrastText: '#000000',
    },
    success: {
      main: '#659265',
      contrastText: '#000000',
    },
    warning: {
      main: '#ff8c00',
      contrastText: '#000000',
    },
  },
})

export default theme
