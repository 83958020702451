import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  MenuList,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import ElevationScroll from "../frame/ElevationScroll";
import { removeToken } from "../../plugin/store/slices/tokenSlice";
import _axios from "../../plugin/axios";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const CollapsibleDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "drawerState",
})(({ theme, drawerState }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerState && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!drawerState && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AppLayout({ children }) {
  // redux变量区
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.value);
  const routesList = useSelector((state) => state.routesList);

  // state变量区
  const [drawerState, setDrawerState] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchResult, setSearchResult] = useState({ novelTitle: "" });

  // 改变侧边栏打开和关闭状态
  const changeDrawerState = () => {
    setDrawerState(!drawerState);
  };

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(removeToken());
  };

  const jumpToICP = () => {
    window.location.href = "https://beian.miit.gov.cn";
  };

  const showNovelDetail = (novelId) => {
    window.open(window.location.origin + "/novel/" + novelId);
  };

  const searchSuggestionsInit = () => {
    let getParams = {
      keyword: searchInputValue ? searchInputValue : null,
      published: 1,
      hot: 1,
      pageSize: 10,
    };
    _axios
      .get("/novel/novel-list", { params: getParams })
      .then((resp) => {
        if (resp.data.code === "00") {
          setSearchSuggestions(resp.data.data.novelInfoList);
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "success",
            message: error.message,
          })
        );
      });
  };

  const changeSearchResult = (value) => {
    setSearchResult(value);
  };

  useEffect(() => {
    searchSuggestionsInit();
  }, [searchInputValue]);

  // 渲染区
  return (
    <Box sx={{ display: "flex" }}>
      <ElevationScroll>
        <AppBar
          color="beige"
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                changeDrawerState();
              }}
              sx={{ mr: 3 }}
            >
              <Icon.Menu />
            </IconButton>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={require("../../assets/logo.png")}
                height={"24px"}
                alt=""
              />
              <Typography variant="h6" component="div" sx={{ ml: 1 }}>
                柏拉图的注目
              </Typography>
            </Box>

            <Autocomplete
              popupIcon
              options={searchSuggestions}
              getOptionLabel={(option) => option.novelTitle}
              value={searchResult}
              onChange={(event, value) => {
                if (value === null) {
                  changeSearchResult({ novelTitle: "" });
                } else {
                  changeSearchResult(value);
                }
              }}
              inputValue={searchInputValue}
              onInputChange={(event, value) => {
                setSearchInputValue(value);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.novelId}>
                  <ListItem
                    onClick={() => {
                      showNovelDetail(option.novelId);
                    }}
                  >
                    <ListItemText primary={option.novelTitle} />
                  </ListItem>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="查找更多文章"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon.Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              sx={{
                position: "absolute",
                zIndex: 1,
                left: "400px",
                right: "400px",
                textAlign: "center",
              }}
            />

            <IconButton
              onClick={(event) => {
                openUserMenu(event);
              }}
            >
              <Avatar
                id="user-menu-button"
                aria-controls={anchorEl ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "user-menu" : undefined}
                sx={{ height: 36, width: 36 }}
              >
                {userInfo.name}
              </Avatar>
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                closeUserMenu();
              }}
              MenuListProps={{
                "aria-labelledby": "user-menu-button",
              }}
            >
              <MenuList dense>
                <MenuItem
                  onClick={() => {
                    logout();
                  }}
                >
                  <ListItemIcon>
                    <Icon.Logout />
                  </ListItemIcon>
                  <ListItemText>退出登录</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <CollapsibleDrawer
        variant="permanent"
        drawerState={drawerState}
        PaperProps={{
          sx: { bgcolor: "#faf5ea" },
        }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Toolbar />
        <List sx={{ flex: 1 }}>
          {routesList.value
            .filter((router) => {
              return router.icon;
            })
            .map((route, index) => (
              <NavLink
                key={index}
                to={route.path}
                style={{ textDecoration: "none" }}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <ListItem sx={{ px: 0.5, py: 0.25 }}>
                    <ListItemButton
                      selected={isActive}
                      sx={{ borderRadius: 1 }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
                        {route.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={route.title}
                        sx={{ color: isActive ? "#000000" : "#9e9e9e" }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </NavLink>
            ))}
        </List>
        {drawerState && (
          <Box sx={{ width: "100%", py: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                mb: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{ cursor: "pointer", fontWeight: "bold" }}
              >
                {"教程"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ cursor: "pointer", fontWeight: "bold" }}
              >
                {"联系我们"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ cursor: "pointer", fontWeight: "bold" }}
              >
                {"博客"}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              component={"div"}
              sx={{ textAlign: "center" }}
            >
              {"Copyright © 2023-" + new Date().getFullYear()}
            </Typography>
            <Typography
              variant="caption"
              component={"div"}
              sx={{ textAlign: "center" }}
            >
              {"柏拉图的注目"}
            </Typography>
            <Typography
              variant="caption"
              component={"div"}
              sx={{ cursor: "pointer", textAlign: "center" }}
              onClick={() => {
                jumpToICP();
              }}
            >
              {"沪ICP备2023024934号-1"}
            </Typography>
          </Box>
        )}
      </CollapsibleDrawer>
      <Box sx={{ bgcolor: "#faf5ea", minHeight: "100vh", flex: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
