import { Box, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import SettingTab from './SettingTab'
import BasicInfoTab from './BasicInfoTab'

const PersonalNav = ({ user }) => {
  const [currentTab, setCurrentTab] = useState('publish')
  const changeCurrentTab = (value) => {
    setCurrentTab(value)
  }

  return (
    <Box>
      <Tabs
        value={currentTab}
        sx={{ borderBottom: '1px solid #e8e8e8' }}
        onChange={(event, value) => {
          changeCurrentTab(value)
        }}
      >
        <Tab label="我的发布" value="publish" />
        <Tab label="我的关注" value="follow" />
        <Tab label="点赞" value="like" />
        <Tab label="我的好友" value="friends" />
        <Box sx={{ flex: 1 }} />
        <Tab label="基本信息" value="basicInfo" />
        <Tab label="设置" value="setting" />
      </Tabs>
      {currentTab === 'basicInfo' && <BasicInfoTab user={user} />}
      {currentTab === 'setting' && <SettingTab />}
    </Box>
  )
}

export default PersonalNav
