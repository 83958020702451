import { createSlice } from '@reduxjs/toolkit'

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    changedFlag: 0,
    type: 'info',
    message: '',
  },
  reducers: {
    setSnackbar: (state, action) => {
      state.changedFlag++
      state.type = action.payload.type
      state.message = action.payload.message
    },
  },
})

export const { setSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
