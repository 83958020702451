import AppLayout from '../components/layout/AppLayout'
import WaterfallNovel from '../components/citizens/WaterfallNovel'

export default function CitizensView() {
  return (
    <AppLayout>
      <WaterfallNovel></WaterfallNovel>
    </AppLayout>
  )
}
