import { Container } from "@mui/material";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Content() {
  // redux变量区
  const novel = useSelector((state) => state.novel);

  // state变量区
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    if (novel.novelId) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(novel.draftRaw))
      );
    }
  }, [novel.novelId]);

  return (
    <Container maxWidth="lg">
      {editorState && <Editor editorState={editorState} onChange={() => {}} />}
    </Container>
  );
}
