import { EditorState, convertToRaw } from "draft-js";

export default {
  draftRaw: convertToRaw(EditorState.createEmpty().getCurrentContent()),
  globalSet: [
    {
      id: 1,
      name: "结合前文",
      active: true,
      setting: { externalInfo: "请结合\\pc()，解释", front: true, back: false },
    },
  ],
  referenceList: [],
  functionSet: [],
};
