import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setSnackbar } from "../plugin/store/slices/snackbarSlice";
import { useEffect } from "react";
import DetailLayout from "../components/layout/DetailLayout";
import { downloadNovel } from "../plugin/tools";
import Content from "../components/novelDetail/Content";

export default function NovelDetailView() {
  // redux变量区
  const dispatch = useDispatch();

  // router变量区
  const currentNovelId = useParams().novelId;

  const novelInit = async () => {
    const syncNovelFromServerResult = await downloadNovel(currentNovelId);
    if (syncNovelFromServerResult.status === "error") {
      dispatch(
        setSnackbar({
          type: "error",
          message: syncNovelFromServerResult.message,
        })
      );
    }
  };

  useEffect(() => {
    novelInit();
  }, []);

  return (
    <DetailLayout>
      <Content />
    </DetailLayout>
  );
}
