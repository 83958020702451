import {
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import styled from "styled-components";
import _axios from "../../plugin/axios";
import { setNovel } from "../../plugin/store/slices/novelSlice";
import { dc } from "../../plugin/tools";

export default function NovelInfo() {
  // redux变量区
  const dispatch = useDispatch();
  const novel = useSelector((state) => state.novel);

  // state变量区
  const [newTag, setNewTag] = useState("");

  const getArrayBufferFromFile = (file, onLoadCallBack) => {
    const fileReader = new FileReader();
    fileReader.onload = onLoadCallBack;
    fileReader.onerror = () => {
      dispatch(
        setSnackbar({
          type: "error",
          message: "文件解析失败，请重新上传",
        })
      );
    };
    fileReader.readAsArrayBuffer(file);
  };

  const getBase64UrlFromArrayBuffer = (arrayBuffer, prefix) => {
    let binary = "";
    const bytes = new Uint8Array(arrayBuffer);
    for (let i = 0; i < bytes.byteLength; i++)
      binary += String.fromCharCode(bytes[i]);

    return prefix + window.btoa(binary);
  };

  const fillTitle = (value) => {
    if (value === "") {
      dispatch(setSnackbar({ type: "error", message: "文章标题不能为空" }));
    } else {
      dispatch(setNovel({ ...novel, title: value }));
    }
  };

  const fillDescription = (value) => {
    if (value === "") {
      dispatch(setSnackbar({ type: "error", message: "文章描述不能为空" }));
    } else {
      dispatch(setNovel({ ...novel, description: value }));
    }
  };

  const deleteTag = (index) => {
    let tmpTagList = dc(novel.tagContentList);
    tmpTagList.splice(index, 1);
    changeTagList([...tmpTagList]);
  };

  const addTag = (value) => {
    if (novel.tagContentList.indexOf(value) !== -1) {
      dispatch(setSnackbar({ type: "error", message: "该标签已存在" }));
    } else {
      changeTagList([...novel.tagContentList, value]);
      setNewTag("");
    }
  };

  const changeTagList = (value) => {
    dispatch(setNovel({ ...novel, tagContentList: value }));
  };

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src={"https://cache.platogaze.com/" + novel.coverUri}
            width={"100%"}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            component="label"
            fullWidth
            variant="contained"
            color="secondary"
          >
            更换封面图片
            <VisuallyHiddenInput
              type="file"
              accept={".png,.jpg,.jpeg"}
              onChange={(event) => {
                getArrayBufferFromFile(
                  event.target.files[0],
                  (onLoadCallBackEvent) => {
                    const postObj = {
                      base64String: getBase64UrlFromArrayBuffer(
                        onLoadCallBackEvent.target.result,
                        `data:${event.target.files[0].type};base64,`
                      ),
                    };
                    _axios
                      .post("/novel/image", postObj)
                      .then((resp) => {
                        if (resp.data.code === "00") {
                          dispatch(
                            setSnackbar({
                              type: "success",
                              message: resp.data.message,
                            })
                          );
                          dispatch(
                            setNovel({ ...novel, coverUri: resp.data.data })
                          );
                        } else {
                          dispatch(
                            setSnackbar({
                              type: "error",
                              message: resp.data.message,
                            })
                          );
                        }
                      })
                      .catch((error) => {
                        dispatch(
                          setSnackbar({
                            type: "error",
                            message: error.message,
                          })
                        );
                      });
                  }
                );
              }}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="文章标题"
            variant="standard"
            margin="normal"
            fullWidth
            value={novel.title}
            onInput={(event) => {
              fillTitle(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="文章描述"
            variant="standard"
            margin="normal"
            fullWidth
            multiline
            minRows={3}
            value={novel.description}
            onInput={(event) => {
              fillDescription(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="添加新标签"
            variant="standard"
            margin="normal"
            fullWidth
            value={newTag}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!newTag}
                    size="small"
                    onClick={() => {
                      addTag(newTag);
                    }}
                  >
                    <Icon.Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onInput={(event) => {
              setNewTag(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {novel.tagContentList.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              sx={{ mr: 1 }}
              onDelete={() => {
                deleteTag(index);
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
