import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Container, Tooltip, Fab } from "@mui/material";
import * as Icon from "@mui/icons-material";
import AppLayout from "../components/layout/AppLayout";
import { setSnackbar } from "../plugin/store/slices/snackbarSlice";
import _axios from "../plugin/axios";
import VerticalNovelItem from "../components/frame/NovelItems/VerticalNovelItem";
import { useNavigate } from "react-router-dom";
import { createNewNovel } from "../plugin/tools";

export default function DesktopView() {
  // redux变量区
  const dispatch = useDispatch();

  // router变量区
  const navigate = useNavigate();

  // state变量区
  const [novelList, setNovelList] = useState([]);
  const [refreshNovelListFlag, setRefreshNovelListFlag] = useState(0);

  const novelListInit = () => {
    const getParams = { published: 0 };
    _axios
      .get("/novel/novel-list", { params: getParams })
      .then((resp) => {
        if (resp.data.code === "00") {
          setNovelList(resp.data.data.novelInfoList);
        } else {
          dispatch(setSnackbar({ type: "error", message: resp.data.message }));
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ type: "error", message: error.message }));
      });
  };

  const editNovel = (novelId) => {
    navigate("/editor/" + novelId);
  };

  const createNovel = async () => {
    const createNewNovelResult = await createNewNovel();
    if (createNewNovelResult.status === "success") {
      editNovel(createNewNovelResult.data);
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: createNewNovelResult.message,
        })
      );
    }
  };

  useEffect(() => {
    novelListInit();
  }, [refreshNovelListFlag]);

  return (
    <AppLayout>
      <Container maxWidth="xl" sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          {novelList.map((novel, index) => (
            <Grid key={index} item xs={4} sm={4} md={3} lg={3} xl={2}>
              <VerticalNovelItem
                key={index}
                novel={novel}
                refreshNovelListFlag={refreshNovelListFlag}
                setRefreshNovelListFlag={setRefreshNovelListFlag}
              />
            </Grid>
          ))}
        </Grid>
        <Tooltip arrow placement="top" title="添加空白文字">
          <Fab
            size="large"
            color="secondary"
            sx={{ position: "fixed", right: 36, bottom: 36 }}
            onClick={() => {
              createNovel();
            }}
          >
            <Icon.Add />
          </Fab>
        </Tooltip>
      </Container>
    </AppLayout>
  );
}
