import {
  Button,
  Container,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../plugin/store/slices/tokenSlice";
import AIProviderOptions from "../../plugin/tools/AIProviderOptions";
import { setSelectedAIProvider } from "../../plugin/store/slices/selectedAIProviderSlice";

export default function SettingTab() {
  // redux 变量区
  const dispatch = useDispatch();
  const selectedAIProvider = useSelector((state) => state.selectedAIProvider);

  const changeAIProvider = (AIProvider) => {
    dispatch(setSelectedAIProvider(AIProvider));
  };

  const logout = () => {
    dispatch(removeToken());
  };

  return (
    <Container maxWidth="sm" sx={{ py: 3 }}>
      <List>
        <ListItem
          secondaryAction={
            <FormControl variant="standard" sx={{ minWidth: "120px" }}>
              <Select value={selectedAIProvider.value}>
                {AIProviderOptions.map((AIProviderOption, index) => (
                  <MenuItem
                    key={index}
                    disabled={AIProviderOption.banned}
                    value={AIProviderOption.value}
                    onClick={() => {
                      changeAIProvider(AIProviderOption);
                    }}
                  >
                    {AIProviderOption.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        >
          <ListItemText primary={"默认AI提供方"} />
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => {
              logout();
            }}
          >
            退出登录
          </Button>
        </ListItem>
      </List>
    </Container>
  );
}
