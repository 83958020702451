import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  addGlobalValue,
  deleteGlobalValue,
  updateGlobalValue,
} from "../../plugin/tools";
import { useEffect, useState } from "react";

export default function GlobalSet() {
  // redux变量区
  const globalSet = useSelector((state) => state.novel).globalSet;

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Tooltip
        placement="top"
        title="添加全局变量
    "
      >
        <Fab
          color="secondary"
          size="small"
          sx={{ position: "absolute", bottom: "12px", right: "12px" }}
          onClick={() => {
            addGlobalValue();
          }}
        >
          <Icon.Add />
        </Fab>
      </Tooltip>
      {globalSet.map((globalValue, index) => (
        <GlobalSetCard key={index} globalValue={globalValue} />
      ))}
    </Container>
  );
}

const GlobalSetCard = ({ globalValue }) => {
  // state变量区
  const [setting, setSetting] = useState(globalValue.setting);
  const [editGlobalValueDialogFlag, setEditGlobalValueDialogFlag] =
    useState(false);

  const editGlobalValueName = () => {
    setEditGlobalValueDialogFlag(true);
  };

  const finishGlobalValueNameEdition = () => {
    setEditGlobalValueDialogFlag(false);
  };

  const updateSetting = () => {
    updateGlobalValue(globalValue.id, globalValue.name, setting);
  };

  useEffect(() => {
    setSetting(globalValue.setting);
  }, [globalValue]);

  useEffect(() => {
    updateSetting();
  }, [setting]);

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent sx={{ pb: 0, display: "flex", alignItems: "center" }}>
        <Tooltip title="修改别名" placement="top" arrow>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            color={"secondary"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              editGlobalValueName();
            }}
          >
            {globalValue.name}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        <Tooltip title="删除全局变量" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              deleteGlobalValue(globalValue.id);
            }}
          >
            <Icon.Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CardContent>
      <CardContent>
        <TextField
          variant="standard"
          fullWidth
          multiline
          placeholder="请输入全局变量处理内容"
          value={setting.externalInfo}
          onInput={(event) => {
            setSetting({ ...setting, externalInfo: event.target.value });
          }}
        />
        <RadioGroup row>
          <FormControlLabel
            control={<Radio size="small" />}
            checked={setting.front}
            onChange={() => {
              setSetting({ ...setting, front: true, back: false });
            }}
            label={"应用在前方"}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            checked={setting.back}
            onChange={() => {
              setSetting({ ...setting, front: false, back: true });
            }}
            label={"应用在后方"}
          />
        </RadioGroup>
      </CardContent>

      <EditGlobalValueNameDialog
        dialogState={editGlobalValueDialogFlag}
        globalValue={globalValue}
        finishEdition={finishGlobalValueNameEdition}
      />
    </Card>
  );
};

const EditGlobalValueNameDialog = ({
  dialogState,
  globalValue,
  finishEdition,
}) => {
  // redux变量区
  const globalSet = useSelector((state) => state.novel).globalSet;

  // state变量区
  const [name, setName] = useState({ value: globalValue.name });

  const fillName = (value) => {
    let nameRepeatChecker = globalSet.find((existedGlobalValue) => {
      return (
        existedGlobalValue.name === value &&
        existedGlobalValue.id !== globalValue.id
      );
    });
    if (nameRepeatChecker) {
      setName({
        ...name,
        value: value,
        errorFlag: true,
        errorMessage: "该名称已经被使用",
      });
    } else {
      setName({
        ...name,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const submitEdition = () => {
    updateGlobalValue(globalValue.id, name.value, globalValue.setting);
    finishEdition();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>修改名称</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          value={name.value}
          error={name.errorFlag}
          helperText={name.errorMessage}
          onInput={(event) => {
            fillName(event.target.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={name.value === "" || name.errorFlag}
          onClick={() => {
            submitEdition();
          }}
        >
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
};
