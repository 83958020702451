import { Box } from "@mui/material";

export default function LoginPoster({}) {
  return (
    <Box height={"100vh"} flex={1}>
      <img
        src={require("../../assets/titlePage.png")}
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "cover",
        }}
      />
    </Box>
  );
}
