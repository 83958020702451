import { Chip, Grid, Skeleton, Typography } from "@mui/material";

export default function HorizontalNovelItem({ novel }) {
  const showNovelDetail = () => {
    window.open(window.location.origin + "/novel/" + novel.novelId);
  };

  return (
    <Grid container spacing={1} columnSpacing={2}>
      {novel.coverUri ? (
        <Grid
          item
          xs={4}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            showNovelDetail(novel.novelId);
          }}
        >
          <img
            src={"https://cache.platogaze.com/" + novel.coverUri}
            width="100%"
            height="140px"
            style={{ objectFit: "cover" }}
          />
        </Grid>
      ) : (
        <Grid item xs={4}>
          <Skeleton variant="rectangular" animation="wave" height={"160px"} />
        </Grid>
      )}
      <Grid item xs={8}>
        <Grid container spacing={1} columnSpacing={2}>
          {novel.novelTitle ? (
            <Grid
              item
              xs={12}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                showNovelDetail(novel.novelId);
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {novel.novelTitle}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Skeleton animation="wave" width="80%" height="32px" />
            </Grid>
          )}
          {novel.description ? (
            <Grid
              item
              xs={12}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                showNovelDetail(novel.novelId);
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  lineHeight: "1.5em",
                  maxHeight: "4.5em",
                }}
              >
                {novel.description}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
            </Grid>
          )}
          {novel.username ? (
            <Grid item xs={4}>
              <Typography variant="body1">{novel.username}</Typography>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Skeleton animation="wave" width="50%" />
            </Grid>
          )}
          {novel.tagContentList ? (
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
              {novel.tagContentList.map((tag, index) => (
                <Chip key={index} size="small" label={tag} sx={{ ml: 1 }} />
              ))}
            </Grid>
          ) : (
            <Grid item xs={8}>
              <Skeleton animation="wave" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
