import { createSlice } from "@reduxjs/toolkit";

export const novelSlice = createSlice({
  name: "novel",
  initialState: {
    novelId: 0,
    title: "",
    coverUri: "",
    description: "",
    tagContentList: [],
    draftRaw: "",
    globalSet: [],
    referenceList: [],
    functionSet: [],
  },
  reducers: {
    setNovel: (state, action) => {
      state.novelId = action.payload.novelId;
      state.title = action.payload.title;
      state.coverUri = action.payload.coverUri;
      state.description = action.payload.description;
      state.tagContentList = action.payload.tagContentList;
      state.draftRaw = action.payload.draftRaw;
      state.globalSet = action.payload.globalSet;
      state.referenceList = action.payload.referenceList;
      state.functionSet = action.payload.functionSet;
    },
  },
});

export const { setNovel } = novelSlice.actions;

export default novelSlice.reducer;
