import {
  Check,
  EditNote,
  PlayArrow,
  PlaylistAddCheck,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  OutlinedInput,
  Popper,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

export function Terminal({
  showExtendedTerminalFlag,
  setShowExtendedTerminalFlag,
  computingInlineFunc,
  inlineFuncSelectionContent,
  updateInlineFuncContent,
  updateEntity,
  isComputing,
  computeInlineFunc,
}) {
  // state变量区
  const [anchorEl, setAnchorEl] = useState(null);
  const [inlineFuncName, setInlineFuncName] = useState("");
  const [inlineFuncContent, setInlineFuncContent] = useState("");

  useEffect(() => {
    setAnchorEl(document.getElementById("terminal-box"));
  }, [showExtendedTerminalFlag]);

  useEffect(() => {
    setInlineFuncName(
      computingInlineFunc.inlineFunc ? computingInlineFunc.inlineFunc : ""
    );
    setInlineFuncContent(inlineFuncSelectionContent);
  }, [computingInlineFunc]);

  return (
    <Box bgcolor={"#faf5ea"} py={1} id={"terminal-box"}>
      <Popper
        open={showExtendedTerminalFlag}
        anchorEl={anchorEl}
        placement={"top"}
      >
        <Box bgcolor={"#faf5ea"} pt={1} width={"1152px"}>
          <OutlinedInput
            id={"terminal-popper"}
            fullWidth
            multiline
            maxRows={12}
            minRows={1}
            value={inlineFuncContent}
            onInput={(event) => {
              setInlineFuncContent(event.target.value);
            }}
            disabled={isComputing}
            sx={{ px: 0.5, py: 0.5, bgcolor: computingInlineFunc.color + "16" }}
          />
        </Box>
      </Popper>
      <OutlinedInput
        fullWidth
        multiline
        minRows={1}
        endAdornment={[
          <Tooltip
            key={v4()}
            arrow
            placement="top"
            title={
              isComputing
                ? "正在计算…"
                : showExtendedTerminalFlag
                ? "修改文本"
                : "编辑绑定文本"
            }
          >
            <span>
              <IconButton
                size="small"
                disabled={!computingInlineFunc.entityKey || isComputing}
                onClick={() => {
                  showExtendedTerminalFlag
                    ? updateInlineFuncContent(
                        computingInlineFunc.uuid,
                        inlineFuncContent
                      )
                    : setShowExtendedTerminalFlag(true);
                }}
              >
                {showExtendedTerminalFlag ? (
                  <PlaylistAddCheck fontSize="inherit" />
                ) : (
                  <EditNote fontSize="inherit" />
                )}
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip
            key={v4()}
            arrow
            placement="top"
            title={isComputing ? "正在计算…" : "修改inlineFunc"}
          >
            <span>
              <IconButton
                size="small"
                disabled={!computingInlineFunc.entityKey || isComputing}
                onClick={() => {
                  updateEntity(computingInlineFunc.uuid, {
                    ...computingInlineFunc,
                    inlineFunc: inlineFuncName,
                  });
                }}
              >
                <Check fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip
            key={v4()}
            arrow
            placement="top"
            title={isComputing ? "正在计算…" : "计算"}
          >
            <span>
              <IconButton
                size="small"
                disabled={!computingInlineFunc.entityKey}
                onClick={() => {
                  computeInlineFunc(computingInlineFunc.uuid);
                }}
              >
                {isComputing ? (
                  <CircularProgress size={18} thickness={6} />
                ) : (
                  <PlayArrow fontSize="inherit" />
                )}
              </IconButton>
            </span>
          </Tooltip>,
        ]}
        value={inlineFuncName}
        onInput={(event) => {
          setInlineFuncName(event.target.value);
        }}
        disabled={isComputing}
        sx={{ pl: 1.5, pr: 0.5, py: 0.5 }}
      />
    </Box>
  );
}
