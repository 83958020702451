import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import QuicknoteList from "../editor/QuicknoteList";
import NovelInfo from "../editor/NovelInfo";
import InlineFuncSet from "../editor/InlineFuncSet";
import FunctionSet from "../editor/FunctionSet";
import ReferenceList from "../editor/ReferenceList";
import { useState } from "react";
import GlobalSet from "./GlobalSet";

export default function EditorDrawer({
  isComputing,
  updateEntity,
  selectInlineFunc,
  computeInlineFunc,
}) {
  const [menus] = useState([
    { menuId: 0, name: "本文InlineFunc", icon: <Icon.FormatIndentIncrease /> },
    { menuId: 1, name: "我的助手", icon: <Icon.Functions /> },
    { menuId: 2, name: "我的引用", icon: <Icon.TextFormat /> },
    { menuId: 3, name: "全局变量", icon: <Icon.Language /> },
    { menuId: 4, name: "我的笔记", icon: <Icon.Book /> },
    { menuId: 5, name: "文章信息", icon: <Icon.Info /> },
  ]);
  const [selectedMenuId, setSelectedMenuId] = useState(0);

  const [drawerState, setDrawerState] = useState(true);
  const [drawerwidth, setDrawerwidth] = useState(464);

  const selectMenu = (menuId) => {
    if (selectedMenuId === menuId) {
      setSelectedMenuId(-1);
      setDrawerState(!drawerState);
    } else {
      setDrawerState(true);
      setSelectedMenuId(menuId);
    }
  };
  return (
    <CollapsibleDrawer
      variant="permanent"
      drawerState={drawerState}
      drawerwidth={drawerwidth}
      PaperProps={{
        sx: { bgcolor: "#faf5ea" },
      }}
      sx={{ zIndex: 1 }}
    >
      <Toolbar />
      <Box display={"flex"}>
        <Box width={"64px"}>
          <List sx={{ flex: 1 }}>
            {menus.map((menu) => (
              <Tooltip key={menu.menuId} title={menu.name} placement="right">
                <ListItem sx={{ px: 0.5, py: 0.25 }}>
                  <ListItemButton
                    selected={selectedMenuId === menu.menuId}
                    onClick={() => {
                      selectMenu(menu.menuId);
                    }}
                    sx={{ borderRadius: 1, py: 2 }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      {menu.icon}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Box>
        {drawerState && (
          <Box width={{ width: drawerwidth - 64 + "px" }}>
            {selectedMenuId === 0 ? (
              <InlineFuncSet
                isComputing={isComputing}
                updateEntity={updateEntity}
                selectInlineFunc={selectInlineFunc}
                computeInlineFunc={computeInlineFunc}
              />
            ) : selectedMenuId === 1 ? (
              <FunctionSet />
            ) : selectedMenuId === 2 ? (
              <ReferenceList />
            ) : selectedMenuId === 3 ? (
              <GlobalSet />
            ) : selectedMenuId === 4 ? (
              <QuicknoteList />
            ) : selectedMenuId === 5 ? (
              <NovelInfo />
            ) : (
              <></>
            )}
          </Box>
        )}
      </Box>
    </CollapsibleDrawer>
  );
}

const openedMixin = (theme, drawerwidth) => ({
  width: drawerwidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const CollapsibleDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "drawerState",
})(({ theme, drawerwidth, drawerState }) => ({
  width: drawerwidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerState && {
    ...openedMixin(theme, drawerwidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
  }),
  ...(!drawerState && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
