import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import tokenReducer from "./slices/tokenSlice";
import userInfoReducer from "./slices/userInfoSlice";
import snackbarReducer from "./slices/snackbarSlice";
import routesListReducer from "./slices/routesListSlice";
import novelReducer from "./slices/novelSlice";
import selectedAIProviderReducer from "./slices/selectedAIProviderSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["token", "userInfo", "selectedAIProvider"],
};

const reducers = combineReducers({
  token: tokenReducer,
  userInfo: userInfoReducer,
  snackbar: snackbarReducer,
  routesList: routesListReducer,
  novel: novelReducer,
  selectedAIProvider: selectedAIProviderReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
