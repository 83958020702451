import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  addReference,
  deleteReference,
  updateReference,
} from "../../plugin/tools";
import { useEffect, useState } from "react";

export default function ReferenceList() {
  // redux变量区
  const referenceList = useSelector((state) => state.novel).referenceList;

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Tooltip placement="top" title="新增引用">
        <Fab
          color="secondary"
          size="small"
          sx={{ position: "absolute", bottom: "12px", right: "12px" }}
          onClick={() => {
            addReference();
          }}
        >
          <Icon.Add />
        </Fab>
      </Tooltip>
      {referenceList.map((referenceListItem, index) => (
        <ReferenceListItemCard
          key={index}
          referenceListItem={referenceListItem}
        />
      ))}
    </Container>
  );
}

const ReferenceListItemCard = ({ referenceListItem }) => {
  // state变量区
  const [content, setContent] = useState(referenceListItem.content);
  const [editReferenceNameDialogFlag, setEditReferenceNameDialogFlag] =
    useState(false);
  const [editReferenceCommentDialogFlag, setEditReferenceCommentDialogFlag] =
    useState(false);

  const editReferenceName = () => {
    setEditReferenceNameDialogFlag(true);
  };

  const finishReferenceNameEdition = () => {
    setEditReferenceNameDialogFlag(false);
  };

  const editReferenceComment = () => {
    setEditReferenceCommentDialogFlag(true);
  };

  const finishReferenceCommentEdition = () => {
    setEditReferenceCommentDialogFlag(false);
  };

  useEffect(() => {
    setContent(referenceListItem.content);
  }, [referenceListItem]);

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent
        sx={{
          pb: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title="修改别名" placement="top" arrow>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            color={"secondary"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              editReferenceName();
            }}
          >
            {referenceListItem.name}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        <Tooltip title="修改备注" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              editReferenceComment();
            }}
          >
            <Icon.Edit fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="删除引用" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              deleteReference(referenceListItem.id);
            }}
          >
            <Icon.Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CardContent>
      <CardContent>
        <TextField
          variant="standard"
          fullWidth
          multiline
          value={content}
          onInput={(event) => {
            setContent(event.target.value);
          }}
          onBlur={() => {
            updateReference(
              referenceListItem.id,
              referenceListItem.name,
              content,
              referenceListItem.comment
            );
          }}
        />
      </CardContent>
      <EditReferenceNameDialog
        dialogState={editReferenceNameDialogFlag}
        referenceListItem={referenceListItem}
        finishEdition={finishReferenceNameEdition}
      />
      <EditReferenceCommentDialog
        dialogState={editReferenceCommentDialogFlag}
        referenceListItem={referenceListItem}
        finishEdition={finishReferenceCommentEdition}
      />
    </Card>
  );
};

const EditReferenceNameDialog = ({
  dialogState,
  referenceListItem,
  finishEdition,
}) => {
  // redux变量区
  const referenceList = useSelector((state) => state.novel).referenceList;

  // state变量区
  const [name, setName] = useState({ value: referenceListItem.name });

  const fillName = (value) => {
    let nameRepeatChecker = referenceList.find((existedReferenceListItem) => {
      return (
        existedReferenceListItem.name === value &&
        existedReferenceListItem.id !== referenceListItem.id
      );
    });
    if (nameRepeatChecker) {
      setName({
        ...name,
        value: value,
        errorFlag: true,
        errorMessage: "该名称已经被使用",
      });
    } else {
      setName({
        ...name,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const submitEdition = () => {
    updateReference(
      referenceListItem.id,
      name.value,
      referenceListItem.content,
      referenceListItem.comment
    );
    finishEdition();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>修改名称</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          value={name.value}
          error={name.errorFlag}
          helperText={name.errorMessage}
          onInput={(event) => {
            fillName(event.target.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={name.value === "" || name.errorFlag}
          onClick={() => {
            submitEdition();
          }}
        >
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditReferenceCommentDialog = ({
  dialogState,
  referenceListItem,
  finishEdition,
}) => {
  // state变量区
  const [comment, setComment] = useState({ value: referenceListItem.comment });

  const fillComment = (value) => {
    let commentIllegalChecker = false;
    if (commentIllegalChecker) {
      setComment({
        ...comment,
        value: value,
        errorFlag: true,
        errorMessage: "改备注涉嫌违规",
      });
    } else {
      setComment({
        ...comment,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const submitEdition = () => {
    updateReference(
      referenceListItem.id,
      referenceListItem.name,
      referenceListItem.content,
      comment.value
    );
    finishEdition();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>修改备注</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          value={comment.value}
          error={comment.errorFlag}
          helperText={comment.errorMessage}
          onInput={(event) => {
            fillComment(event.target.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={comment.errorFlag}
          onClick={() => {
            submitEdition();
          }}
        >
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
};
