import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import _axios from "../../plugin/axios";
import { removeToken } from "../../plugin/store/slices/tokenSlice";
import { removeUserInfo } from "../../plugin/store/slices/userInfoSlice";

export default function BasicInfoTab({ user }) {
  // redux 变量区
  const dispatch = useDispatch();

  // state变量区
  const [genderOptions] = useState([
    { genderId: 0, gender: "男" },
    { genderId: 1, gender: "女" },
  ]);
  const [username] = useState({ value: user.name });
  const [gender, setGender] = useState({ value: user.gender });
  const [phoneNumber, setPhoneNumber] = useState({ value: user.phoneNumber });
  const [email, setEmail] = useState({ value: user.email });
  const [job, setJob] = useState({ value: user.job });
  const [location, setLocation] = useState({ value: user.location });
  const [selfIntroduction, setSelfIntroduction] = useState({
    value: user.selfIntroduction,
  });

  const changeGender = (value) => {
    setGender({
      value: value,
    });
  };

  const fillPhoneNumber = (value) => {
    const phoneReg = /^1[2-9]\d{9}$/;
    if (phoneReg.test(value)) {
      setPhoneNumber({
        ...phoneNumber,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    } else {
      setPhoneNumber({
        ...phoneNumber,
        value: value,
        errorFlag: true,
        errorMessage: "手机号码格式错误",
      });
    }
  };

  const fillEmail = (value) => {
    const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+$/;
    if (emailReg.test(value)) {
      setEmail({
        ...email,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    } else {
      setEmail({
        ...email,
        value: value,
        errorFlag: true,
        errorMessage: "邮箱格式错误",
      });
    }
  };

  const fillJob = (value) => {
    setJob({
      ...job,
      value: value,
      errorFlag: false,
      errorMessage: "",
    });
  };

  const fillLocation = (value) => {
    setLocation({
      ...location,
      value: value,
      errorFlag: false,
      errorMessage: "",
    });
  };

  const fillSelfIntroduction = (value) => {
    setSelfIntroduction({
      ...selfIntroduction,
      value: value,
      errorFlag: false,
      errorMessage: "",
    });
  };

  const changeBasicInfo = () => {
    let putObj = {
      userId: user.userId,
      gender: gender.value,
      phoneNumber: phoneNumber.value,
      email: email.value,
      job: job.value,
      location: location.value,
      selfIntroduction: selfIntroduction.value,
    };
    _axios
      .put("/rbac/user", putObj)
      .then((resp) => {
        if (resp.data.code === "00") {
          dispatch(
            setSnackbar({
              type: "success",
              message: resp.data.message,
            })
          );
          setTimeout(() => {
            dispatch(removeToken);
            dispatch(removeUserInfo);
          }, 3000);
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "error",
            message: error.message,
          })
        );
      });
  };

  return (
    <Container maxWidth="sm" sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="用户名"
            value={username.value}
            variant="standard"
            margin="normal"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl
            variant="standard"
            margin="normal"
            fullWidth
            error={gender.errorFlag}
          >
            <InputLabel>性别</InputLabel>
            <Select
              value={gender.value}
              onChange={(event) => {
                changeGender(event.target.value);
              }}
            >
              {genderOptions.map((genderOption, index) => (
                <MenuItem key={index} value={genderOption.genderId}>
                  {genderOption.gender}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{gender.errorMessage}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="手机号码"
            value={phoneNumber.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={phoneNumber.errorFlag}
            helperText={phoneNumber.errorMessage}
            onInput={(event) => {
              fillPhoneNumber(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="邮箱地址"
            value={email.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={email.errorFlag}
            helperText={email.errorMessage}
            onInput={(event) => {
              fillEmail(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="工作类型"
            value={job.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={job.errorFlag}
            helperText={job.errorMessage}
            onInput={(event) => {
              fillJob(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="所在地"
            value={location.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={location.errorFlag}
            helperText={location.errorMessage}
            onInput={(event) => {
              fillLocation(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="自我介绍"
            value={selfIntroduction.value}
            variant="standard"
            margin="normal"
            fullWidth
            multiline
            error={selfIntroduction.errorFlag}
            helperText={selfIntroduction.errorMessage}
            onInput={(event) => {
              fillSelfIntroduction(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              changeBasicInfo();
            }}
          >
            确认修改
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
