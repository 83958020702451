import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

export default function PersonalInfoBar({ user }) {
  return (
    <ListItem sx={{ height: "96px", mb: 2 }}>
      <ListItemAvatar sx={{ mr: 2 }}>
        <Avatar sx={{ width: "64px", height: "64px" }} />
      </ListItemAvatar>
      <ListItemText
        primary={user.name}
        primaryTypographyProps={{ variant: "h5" }}
        secondary={user.selfIntroduction}
        secondaryTypographyProps={{ variant: "body1" }}
      />
    </ListItem>
  );
}
