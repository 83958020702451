import {
  Box,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  deleteSelectedNovel,
  disableShareSelectedNovel,
  shareSelectedNovel,
} from "../../../plugin/tools";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../plugin/store/slices/snackbarSlice";

export default function VerticalNovelItem({
  novel,
  refreshNovelListFlag,
  setRefreshNovelListFlag,
}) {
  // redux变量区
  const dispatch = useDispatch();

  // router变量区
  const navigate = useNavigate();

  const editNovel = (novelId) => {
    navigate("/editor/" + novelId);
  };

  const shareNovel = async (novelId) => {
    const shareSelectedNovelResult = await shareSelectedNovel(novelId);
    if (shareSelectedNovelResult.status === "success") {
      dispatch(
        setSnackbar({
          type: "success",
          message: shareSelectedNovelResult.data,
        })
      );
      setRefreshNovelListFlag(refreshNovelListFlag + 1);
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: shareSelectedNovelResult.message,
        })
      );
    }
  };

  const disableShareNovel = async (novelId) => {
    const disableShareSelectedNovelResult = await disableShareSelectedNovel(
      novelId
    );
    if (disableShareSelectedNovelResult.status === "success") {
      dispatch(
        setSnackbar({
          type: "success",
          message: disableShareSelectedNovelResult.data,
        })
      );
      setRefreshNovelListFlag(refreshNovelListFlag + 1);
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: disableShareSelectedNovelResult.message,
        })
      );
    }
  };

  const deleteNovel = async (novelId) => {
    const deleteSelectedNovelResult = await deleteSelectedNovel(novelId);
    if (deleteSelectedNovelResult.status === "success") {
      dispatch(
        setSnackbar({
          type: "success",
          message: deleteSelectedNovelResult.data,
        })
      );
      setRefreshNovelListFlag(refreshNovelListFlag + 1);
    } else {
      dispatch(
        setSnackbar({
          type: "error",
          message: deleteSelectedNovelResult.message,
        })
      );
    }
  };

  return (
    <Grid container spacing={1} columnSpacing={2}>
      {novel.coverUri ? (
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            editNovel(novel.novelId);
          }}
        >
          <img
            src={"https://cache.platogaze.com/" + novel.coverUri}
            width="100%"
            height="140px"
            style={{ objectFit: "cover" }}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="140px" />
        </Grid>
      )}
      {novel.novelTitle ? (
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            editNovel(novel.novelId);
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {novel.novelTitle}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton animation="wave" width="80%" height="32px" />
        </Grid>
      )}
      {novel.description ? (
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            editNovel(novel.article_id);
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              lineHeight: "1.5em",
              maxHeight: "4.5em",
            }}
          >
            {novel.description}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="60%" />
        </Grid>
      )}
      {novel.username ? (
        <Grid item xs={4}>
          <Typography variant="body1">{novel.username}</Typography>
        </Grid>
      ) : (
        <Grid item xs={4}>
          <Skeleton animation="wave" width="50%" />
        </Grid>
      )}
      {novel.tagContentList ? (
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
          {novel.tagContentList.map((tag, index) => (
            <Chip key={index} size="small" label={tag} sx={{ ml: 1 }} />
          ))}
        </Grid>
      ) : (
        <Grid item xs={8}>
          <Skeleton animation="wave" />
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Tooltip title="编辑" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              editNovel(novel.novelId);
            }}
          >
            <Icon.Edit fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {novel.state === 0 ? (
          <Tooltip title="分享" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => {
                shareNovel(novel.novelId);
              }}
            >
              <Icon.Share fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="取消分享" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => {
                disableShareNovel(novel.novelId);
              }}
            >
              <Icon.Close fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flex: 1 }} />
        <Tooltip title="删除" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              deleteNovel(novel.novelId);
            }}
          >
            <Icon.Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
