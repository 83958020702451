import "wc-waterfall";

const Waterfall = ({ scrollRef, gap = 10, cols = 2, itemList, itemRender }) => {
  return (
    <wc-waterfall gap={gap} cols={cols}>
      {itemList.map((item, idx) => itemRender(item, idx))}
    </wc-waterfall>
  );
};

export default Waterfall;
