import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useSelector } from "react-redux";
import { addFunc, deleteFunc, updateFunc } from "../../plugin/tools";
import { useEffect, useState } from "react";
import { format } from "date-fns";

export default function FunctionSet() {
  // redux变量区
  const functionSet = useSelector((state) => state.novel).functionSet;

  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Tooltip placement="top" title="新增助手">
        <Fab
          color="secondary"
          size="small"
          sx={{ position: "absolute", bottom: "12px", right: "12px" }}
          onClick={() => {
            addFunc();
          }}
        >
          <Icon.Add />
        </Fab>
      </Tooltip>
      {functionSet.map((functionSetItem, index) => (
        <FunctionSetItemCard key={index} functionSetItem={functionSetItem} />
      ))}
    </Container>
  );
}

const FunctionSetItemCard = ({ functionSetItem }) => {
  // state变量区
  const [method, setMethod] = useState(functionSetItem.method);
  const [editFunctionNameDialogFlag, setEditFunctionNameDialogFlag] =
    useState(false);
  const [checkFunctionHistoryDialogFlag, setCheckFunctionHistoryDialogFlag] =
    useState(false);

  const editFuncitonName = () => {
    setEditFunctionNameDialogFlag(true);
  };

  const finishFunctionNameEdition = () => {
    setEditFunctionNameDialogFlag(false);
  };

  const checkFunctionHistory = () => {
    setCheckFunctionHistoryDialogFlag(true);
  };

  const finishFunctionHistoryCheck = () => {
    setCheckFunctionHistoryDialogFlag(false);
  };

  useEffect(() => {
    setMethod(functionSetItem.method);
  }, [functionSetItem]);

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent sx={{ pb: 0, display: "flex", alignItems: "center" }}>
        <Tooltip title="修改别名" placement="top" arrow>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            color={"secondary"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              editFuncitonName();
            }}
          >
            {functionSetItem.name}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        <Tooltip title="查看历史记录" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              checkFunctionHistory();
            }}
          >
            <Icon.History fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="删除工具块" placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              deleteFunc(functionSetItem.id);
            }}
          >
            <Icon.Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CardContent>
      <CardContent>
        <TextField
          variant="standard"
          fullWidth
          multiline
          placeholder="请输入[引用名]进行引用"
          value={method}
          onInput={(event) => {
            setMethod(event.target.value);
          }}
          onBlur={() => {
            updateFunc(
              functionSetItem.id,
              functionSetItem.name,
              method,
              functionSetItem.history
            );
          }}
        />
      </CardContent>
      <EditFunctionNameDialog
        dialogState={editFunctionNameDialogFlag}
        functionSetItem={functionSetItem}
        finishEdition={finishFunctionNameEdition}
      />
      <CheckFunctionHistroyDialog
        dialogState={checkFunctionHistoryDialogFlag}
        functionSetItem={functionSetItem}
        finishEdition={finishFunctionHistoryCheck}
      />
    </Card>
  );
};

const EditFunctionNameDialog = ({
  dialogState,
  functionSetItem,
  finishEdition,
}) => {
  // redux变量区
  const functionSet = useSelector((state) => state.novel).functionSet;

  // state变量区
  const [name, setName] = useState({ value: functionSetItem.name });

  const fillName = (value) => {
    let nameRepeatChecker = functionSet.find((existedFunctionSetItem) => {
      return (
        existedFunctionSetItem.name === value &&
        existedFunctionSetItem.id !== functionSetItem.id
      );
    });
    if (nameRepeatChecker) {
      setName({
        ...name,
        value: value,
        errorFlag: true,
        errorMessage: "该名称已经被使用",
      });
    } else {
      setName({
        ...name,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const submitEdition = () => {
    updateFunc(
      functionSetItem.id,
      name.value,
      functionSetItem.method,
      functionSetItem.history
    );
    finishEdition();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>修改名称</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          value={name.value}
          error={name.errorFlag}
          helperText={name.errorMessage}
          onInput={(event) => {
            fillName(event.target.value);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={name.value === "" || name.errorFlag}
          onClick={() => {
            submitEdition();
          }}
        >
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CheckFunctionHistroyDialog = ({
  dialogState,
  functionSetItem,
  finishEdition,
}) => {
  const selectHistoryMethod = (method) => {
    updateFunc(
      functionSetItem.id,
      functionSetItem.name,
      method,
      functionSetItem.history
    );
    finishEdition();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>历史记录</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <List>
          {functionSetItem.history &&
            functionSetItem.history.map((record, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <Button
                    size="small"
                    onClick={() => {
                      selectHistoryMethod(record.method);
                    }}
                  >
                    {"恢复"}
                  </Button>
                }
              >
                <ListItemText
                  primary={format(record.time, "y/MM/dd HH:mm")}
                  secondary={record.method}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
