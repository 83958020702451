import { Box } from "@mui/material";
import LoginCard from "../components/login/LoginCard";
import LoginPoster from "../components/login/LoginPoster";

export default function LoginView() {
  return (
    <Box display={"flex"}>
      <LoginPoster />
      <LoginCard />
    </Box>
  );
}
