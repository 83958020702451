import { createSlice } from '@reduxjs/toolkit'

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { value: {} },
  reducers: {
    setUserInfo: (state, action) => {
      state.value = action.payload
    },
    removeUserInfo: (state) => {
      state.value = {}
    },
  },
})

export const { setUserInfo, removeUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer
