import { EditorState, convertFromRaw } from "draft-js";
import _axios from "../axios";
import { store } from "../store";
import { setNovel } from "../store/slices/novelSlice";
import defaultCoverImage from "./defaultCoverImage";
import defaultNovel from "./defaultNovel";

// 深度拷贝对象值
function dc(object) {
  return JSON.parse(JSON.stringify(object));
}

// 获取保留字
function getReservedWords() {
  let funcReservedWords = [];
  store.getState().novel.functionSet.forEach((func) => {
    funcReservedWords.push({ word: func.name, type: "function" });
  });
  return [
    ...funcReservedWords,
    { word: "f", type: "inlineFunction" },
    { word: "pc", type: "previewContent" },
  ];
}

// 判断reservedWord格式正确性
function isLegalReservedWord(checkingWord) {
  let isLegal = true;
  if (checkingWord.indexOf("\\") !== 0) {
    isLegal = false;
  }
  if (
    !getReservedWords().find((reservedWord) => {
      return reservedWord.word === getNameOfReservedWord(checkingWord);
    })
  ) {
    isLegal = false;
  }
  if (checkingWord.lastIndexOf(")") !== checkingWord.length - 1) {
    isLegal = false;
  }
  if (
    checkingWord.split("").filter((char) => {
      return char === "(";
    }).length !==
    checkingWord.split("").filter((char) => {
      return char === ")";
    }).length
  ) {
    isLegal = false;
  }
  return isLegal;
}

// 获取两个括号中的内容
function getContentInParentheses(str) {
  return str.slice(str.indexOf("(") + 1, str.lastIndexOf(")"));
}

// 获取保留字名称
const getNameOfReservedWord = (str) => {
  return str.substring(str.indexOf("\\") + 1, str.indexOf("("));
};

// 获取合法的参数
function getLegalParameters(func) {
  const originalParameters = getContentInParentheses(func).split(",");

  let legalParameter = "";
  let legalParameters = [];

  for (let parameter of originalParameters) {
    legalParameter += parameter;
    if (
      legalParameter.split("").filter((char) => {
        return char === "(";
      }).length ===
      legalParameter.split("").filter((char) => {
        return char === ")";
      }).length
    ) {
      legalParameters.push(legalParameter);
      legalParameter = "";
    } else {
      legalParameter += ",";
    }
  }
  return legalParameters;
}

// 计算保留字内容
async function computeReservedWord(reservedWord, uuid, selectedGlobalIdSet) {
  let finalResult = {};

  // 检查reservedWord的合法性
  if (isLegalReservedWord(reservedWord)) {
    // 声明常用变量
    let aiContent = "";
    const editorState = EditorState.createWithContent(
      convertFromRaw(store.getState().novel.draftRaw)
    );
    const selectedGlobalSet = store
      .getState()
      .novel.globalSet.filter((globalValue) => {
        return selectedGlobalIdSet.includes(globalValue.id);
      });
    const referenceList = store.getState().novel.referenceList;
    const functionSet = store.getState().novel.functionSet;
    const getReservedWordList = (str) => {
      const charList = str.replaceAll(" ", "").split("");
      let reservedWordList = [];
      let index = 0;
      while (index < charList.length) {
        if (charList[index] === "\\") {
          let reservedWord = "";
          while (
            !isLegalReservedWord(reservedWord) &&
            index < charList.length
          ) {
            reservedWord += charList[index];
            index++;
          }
          reservedWordList.push(reservedWord);
        } else {
          index++;
        }
      }
      return reservedWordList;
    };
    const replaceReservedWord = async (str) => {
      let replacedStr = str;
      // 获取str中的保留字内容
      const reservedWordListInStr = getReservedWordList(str);
      // 递归调用computeReservedWord方法将str中的保留字替换为运算结果
      for (let reservedWordInStr of reservedWordListInStr) {
        if (isLegalReservedWord(reservedWordInStr)) {
          const computeReservedWordResult = await computeReservedWord(
            reservedWordInStr,
            uuid,
            selectedGlobalIdSet
          );
          const reservedWordInStrComputeResult =
            computeReservedWordResult.status === "success"
              ? computeReservedWordResult.data
              : "";
          replacedStr = replacedStr.replace(
            reservedWordInStr,
            reservedWordInStrComputeResult
          );
        }
      }
      return replacedStr;
    };
    const replaceReferenceInAiContent = () => {
      // 获取aiContent中的引用位，并使用引用内容替换引用位
      const methodMetionedReferenceIdList = aiContent.match(/\[(.+?)\]/g);
      if (methodMetionedReferenceIdList) {
        methodMetionedReferenceIdList.forEach((methodMetionedReferenceId) => {
          const usingReferenceName = methodMetionedReferenceId
            .replace("[", "")
            .replace("]", "");
          const reference = referenceList.find((reference) => {
            return reference.name === usingReferenceName;
          });
          if (reference) {
            aiContent = aiContent.replaceAll(
              "[" + usingReferenceName + "]",
              reference.content
            );
          }
        });
      }
    };
    const replaceParameterInAiContent = (parameterList) => {
      const methodParameterIdList = aiContent.match(/\((.+?)\)/g);
      if (methodParameterIdList) {
        methodParameterIdList.forEach((methodParameterId) => {
          const usingParameterId = parseInt(
            methodParameterId.replace("(", "").replace(")", "")
          );
          aiContent = aiContent.replaceAll(
            "(" + usingParameterId + ")",
            parameterList[usingParameterId - 1]
          );
        });
      }
    };
    const answerFormatter = (answer) => {
      answer = answer
        .replace(/\\n/g, "\n")
        .replace(/\r/g, "\n")
        .replace(/\*\*/g, "");
      let answerArray = answer.split("\n");
      let answerList = [];
      answerArray.forEach(function (p) {
        if (p.trim()) {
          answerList.push(p.trim());
        }
      });
      return answerList.join("\n");
    };

    // 判断reservedWord类型
    const legalReservedWord = getReservedWords().find((word) => {
      return word.word === getNameOfReservedWord(reservedWord);
    });

    // 如果是封装方法，执行如下内容
    if (legalReservedWord.type === "function") {
      // 找到使用的封装方法
      const targetFunctionSetItem = functionSet.find((functionSetItem) => {
        return functionSetItem.name === getNameOfReservedWord(reservedWord);
      });

      // 构造传递给AI的方法内容
      aiContent = targetFunctionSetItem.method;

      // 记录调用记录
      updateFunc(
        targetFunctionSetItem.id,
        targetFunctionSetItem.name,
        targetFunctionSetItem.method,
        [
          { time: Date(), method: targetFunctionSetItem.method },
          ...targetFunctionSetItem.history,
        ]
      );

      // 获取方法变量，并运算出方法变量的所有内容
      let finalParameters = [];
      for (let parameter of getLegalParameters(reservedWord)) {
        finalParameters.push(await replaceReservedWord(parameter));
      }

      // 获取封装方法中的变量位，并使用计算好的变量内容替换变量位
      replaceParameterInAiContent(finalParameters);

      // 获取aiContent中的引用位，并使用引用内容替换引用位
      replaceReferenceInAiContent();
    }
    // 如果是行内方法，执行如下内容
    else if (legalReservedWord.type === "inlineFunction") {
      // 获取括号中的内容并替换保留字
      aiContent = await replaceReservedWord(
        getContentInParentheses(reservedWord)
      );

      // 获取aiContent中的引用位，并使用引用内容替换引用位
      replaceReferenceInAiContent();
    }
    // 如果是前文，执行如下内容
    else if (legalReservedWord.type === "previewContent") {
      const currentContent = editorState.getCurrentContent();
      let pc = "";
      let findUuidFlag = false;
      for (let block of currentContent.getBlocksAsArray()) {
        for (let characterMetadata of block.getCharacterList()) {
          const entityKey = characterMetadata.getEntity();
          if (
            entityKey &&
            currentContent.getEntity(entityKey).data.uuid === uuid
          ) {
            findUuidFlag = true;
            break;
          }
        }
        if (!findUuidFlag) {
          pc = block.getText();
        } else {
          break;
        }
      }
      finalResult = { status: "success", data: pc };
    }

    // 如果需要调用AI，构造AI请求
    if (aiContent) {
      // 构造AI请求前，插入全局变量改变AI的规则
      for (let selectedGlobalValue of selectedGlobalSet) {
        let finalExternalInfo = await replaceReservedWord(
          selectedGlobalValue.setting.externalInfo
        );
        if (selectedGlobalValue.setting.front) {
          aiContent = finalExternalInfo + aiContent;
        } else if (selectedGlobalValue.setting.back) {
          aiContent = aiContent + finalExternalInfo;
        }
      }
      const getParams = {
        content: aiContent,
        AIProvider: store.getState().selectedAIProvider.value,
      };
      await _axios
        .get("/novel/ai", { params: getParams })
        .then((resp) => {
          if (resp.data.code === "00") {
            finalResult = {
              status: "success",
              data: answerFormatter(resp.data.data.answer),
            };
          } else {
            finalResult = { status: "error", message: resp.data.message };
          }
        })
        .catch((error) => {
          finalResult = { status: "error", message: error.message };
        });
    }
  } else {
    finalResult = { status: "error", message: "格式错误，无法计算" };
  }

  return finalResult;
}

// 添加引用
function addReference() {
  let tmpReferenceList = dc(store.getState().novel.referenceList);
  let maxReferenceId = 0;
  tmpReferenceList.forEach((reference) => {
    maxReferenceId =
      reference.id > maxReferenceId ? reference.id : maxReferenceId;
  });

  tmpReferenceList.splice(tmpReferenceList.length, 0, {
    id: maxReferenceId + 1,
    name: "ref" + (maxReferenceId + 1),
    content: "",
    comment: "",
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, referenceList: tmpReferenceList })
  );
}

// 修改引用
function updateReference(referenceId, name, content, comment) {
  let tmpReferenceList = dc(store.getState().novel.referenceList);
  const updatingReferenceIndex = tmpReferenceList.findIndex((reference) => {
    return reference.id === referenceId;
  });

  tmpReferenceList.splice(updatingReferenceIndex, 1, {
    id: referenceId,
    name,
    content,
    comment,
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, referenceList: tmpReferenceList })
  );
}

// 删除引用
function deleteReference(referenceId) {
  let tmpReferenceList = dc(store.getState().novel.referenceList);
  const deletingReferenceIndex = tmpReferenceList.findIndex((reference) => {
    return reference.id === referenceId;
  });

  tmpReferenceList.splice(deletingReferenceIndex, 1);

  store.dispatch(
    setNovel({ ...store.getState().novel, referenceList: tmpReferenceList })
  );
}

// 添加方法
function addFunc() {
  let tmpFunctionSet = dc(store.getState().novel.functionSet);
  let maxFuncId = 0;
  tmpFunctionSet.forEach((func) => {
    maxFuncId = func.id > maxFuncId ? func.id : maxFuncId;
  });

  tmpFunctionSet.splice(tmpFunctionSet.length, 0, {
    id: maxFuncId + 1,
    name: "func" + (maxFuncId + 1),
    method: "",
    history: [],
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, functionSet: tmpFunctionSet })
  );
}

// 修改方法
function updateFunc(funcId, name, method, history) {
  let tmpFunctionSet = dc(store.getState().novel.functionSet);
  const updatingFuncIndex = tmpFunctionSet.findIndex((func) => {
    return func.id === funcId;
  });

  tmpFunctionSet.splice(updatingFuncIndex, 1, {
    id: funcId,
    name,
    method,
    history,
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, functionSet: tmpFunctionSet })
  );
}

// 删除方法
function deleteFunc(funcId) {
  let tmpFunctionSet = dc(store.getState().novel.functionSet);
  const deletingFuncIndex = tmpFunctionSet.findIndex((func) => {
    return func.id === funcId;
  });

  tmpFunctionSet.splice(deletingFuncIndex, 1);

  store.dispatch(
    setNovel({ ...store.getState().novel, functionSet: tmpFunctionSet })
  );
}

// 添加全局变量
function addGlobalValue() {
  let tmpGlobalSet = dc(store.getState().novel.globalSet);
  let maxGlobalValueId = 0;
  tmpGlobalSet.forEach((globalValue) => {
    maxGlobalValueId =
      globalValue.id > maxGlobalValueId ? globalValue.id : maxGlobalValueId;
  });

  tmpGlobalSet.splice(tmpGlobalSet.length, 0, {
    id: maxGlobalValueId + 1,
    name: "globalValue" + (maxGlobalValueId + 1),
    setting: { externalInfo: "", front: true, back: false },
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, globalSet: tmpGlobalSet })
  );
}

// 修改全局变量
function updateGlobalValue(globalValueId, name, setting) {
  let tmpGlobalSet = dc(store.getState().novel.globalSet);
  const updatingGlobalValueIndex = tmpGlobalSet.findIndex((globalValue) => {
    return globalValue.id === globalValueId;
  });

  tmpGlobalSet.splice(updatingGlobalValueIndex, 1, {
    id: globalValueId,
    name,
    setting,
  });

  store.dispatch(
    setNovel({ ...store.getState().novel, globalSet: tmpGlobalSet })
  );
}

// 删除全局变量
function deleteGlobalValue(globalValueId) {
  let tmpGlobalSet = dc(store.getState().novel.globalSet);
  const deletingGlobalValueIndex = tmpGlobalSet.findIndex((globalValue) => {
    return globalValue.id === globalValueId;
  });

  tmpGlobalSet.splice(deletingGlobalValueIndex, 1);

  store.dispatch(
    setNovel({ ...store.getState().novel, globalSet: tmpGlobalSet })
  );
}

// 将服务器返回结果添加到novel中
async function downloadNovel(novelId) {
  let finalResult = { status: "success" };
  const getParams = {
    novelId: novelId,
  };
  await _axios
    .get("/novel/novel", { params: getParams })
    .then((resp) => {
      if (resp.data.code === "00") {
        store.dispatch(
          setNovel({
            novelId: novelId,
            title: resp.data.data.novelTitle,
            coverUri: resp.data.data.coverUri,
            description: resp.data.data.description,
            tagContentList: resp.data.data.tagContentList,
            draftRaw: JSON.parse(resp.data.data.jsonString1),
            globalSet: JSON.parse(resp.data.data.jsonString2),
            referenceList: JSON.parse(resp.data.data.jsonString3),
            functionSet: JSON.parse(resp.data.data.jsonString4),
          })
        );
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 向服务器更新novel内容
async function updateNovel() {
  let finalResult = { status: "success" };
  const putObj = {
    novelId: store.getState().novel.novelId,
    coverUri: store.getState().novel.coverUri,
    jsonString1: JSON.stringify(store.getState().novel.draftRaw),
    jsonString2: JSON.stringify(store.getState().novel.globalSet),
    jsonString3: JSON.stringify(store.getState().novel.referenceList),
    jsonString4: JSON.stringify(store.getState().novel.functionSet),
    novelTitle: store.getState().novel.title,
    description: store.getState().novel.description,
    tagList: store.getState().novel.tagContentList,
  };
  await _axios
    .put("/novel/novel", putObj)
    .then((resp) => {
      if (resp.data.code === "00") {
        finalResult = { status: "success" };
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 向服务器创建新文章
async function createNewNovel() {
  let finalResult = {};
  const imagePostObj = {
    base64String: defaultCoverImage,
  };
  await _axios
    .post("/novel/image", imagePostObj)
    .then(async (imageResp) => {
      if (imageResp.data.code === "00") {
        const novelPostObj = {
          coverUri: imageResp.data.data,
          jsonString1: JSON.stringify(defaultNovel.draftRaw),
          jsonString2: JSON.stringify(defaultNovel.globalSet),
          jsonString3: JSON.stringify(defaultNovel.referenceList),
          jsonString4: JSON.stringify(defaultNovel.functionSet),
          plate: 0,
          state: 0,
          sourceNovelId: 0,
          novelTitle: "新建文字",
          description: "这个人很懒，还没有留下任何描述～",
          tagList: [],
        };
        await _axios
          .post("/novel/novel", novelPostObj)
          .then((novelResp) => {
            if (novelResp.data.code === "00") {
              finalResult = {
                status: "success",
                data: novelResp.data.data,
              };
            } else {
              finalResult = {
                status: "error",
                message: novelResp.data.message,
              };
            }
          })
          .catch((error) => {
            finalResult = { status: "error", message: error.message };
          });
      } else {
        finalResult = { status: "error", message: imageResp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 拷贝服务器文章到服务器
async function copyCurrentNovel() {
  let finalResult = {};
  const postObj = {
    coverUri: store.getState().novel.coverUri,
    jsonString1: JSON.stringify(store.getState().novel.draftRaw),
    jsonString2: JSON.stringify(store.getState().novel.globalSet),
    jsonString3: JSON.stringify(store.getState().novel.referenceList),
    jsonString4: JSON.stringify(store.getState().novel.functionSet),
    plate: 0,
    state: 0,
    sourceNovelId: store.getState().novel.novelId,
    novelTitle: store.getState().novel.title + " 拷贝",
    description: store.getState().novel.description,
    tagList: store.getState().novel.tagContentList,
  };
  await _axios
    .post("/novel/novel", postObj)
    .then((resp) => {
      if (resp.data.code === "00") {
        finalResult = { status: "success", data: resp.data.data };
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 删除服务器文章
async function deleteSelectedNovel(novelId) {
  let finalResult = {};
  const deleteParams = { novelId };
  await _axios
    .delete("/novel/novel", { params: deleteParams })
    .then((resp) => {
      if (resp.data.code === "00") {
        finalResult = { status: "success", data: "删除文章成功！" };
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 分享服务器文章
async function shareSelectedNovel(novelId) {
  let finalResult = {};
  const putObj = { novelId, state: 1 };
  await _axios
    .put("/novel/novel", putObj)
    .then((resp) => {
      if (resp.data.code === "00") {
        finalResult = { status: "success", data: "分享成功！" };
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

// 取消服务器文章分享
async function disableShareSelectedNovel(novelId) {
  let finalResult = {};
  const putObj = { novelId, state: 0 };
  await _axios
    .put("/novel/novel", putObj)
    .then((resp) => {
      if (resp.data.code === "00") {
        finalResult = { status: "success", data: "取消分享成功！" };
      } else {
        finalResult = { status: "error", message: resp.data.message };
      }
    })
    .catch((error) => {
      finalResult = { status: "error", message: error.message };
    });
  return finalResult;
}

export {
  dc,
  isLegalReservedWord,
  computeReservedWord,
  addReference,
  updateReference,
  deleteReference,
  addFunc,
  updateFunc,
  deleteFunc,
  addGlobalValue,
  updateGlobalValue,
  deleteGlobalValue,
  downloadNovel,
  updateNovel,
  createNewNovel,
  copyCurrentNovel,
  deleteSelectedNovel,
  shareSelectedNovel,
  disableShareSelectedNovel,
};
