import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Terminal } from "./Terminal";
import {
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { setNovel } from "../../plugin/store/slices/novelSlice";
import { v4 } from "uuid";
import { computeReservedWord, isLegalReservedWord } from "../../plugin/tools";
import EditorDrawer from "./EditorDrawer";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import inlineFuncColorOptions from "../../plugin/tools/inlineFuncColorOptions";

export default function NovelContent() {
  // redux变量区
  const dispatch = useDispatch();
  const novel = useSelector((state) => state.novel);

  // state变量区
  const [plainTextLength, setPlainTextLength] = useState(0);
  const [editorState, setEditorState] = useState(null);
  const [inlineFuncSelectionContent, setInlineFuncSelectionContent] =
    useState("");
  const [computingInlineFunc, setComputingInlineFunc] = useState({});
  const [isComputing, setIsComputing] = useState(null);
  const [showExtendedTerminalFlag, setShowExtendedTerminalFlag] =
    useState(false);

  const decorator = new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "inlineFunc"
          );
        }, callback);
      },
      component: ({ entityKey, contentState, children }) => {
        const color = contentState.getEntity(entityKey).getData().color;
        return (
          <Box
            display={"inline"}
            sx={{
              textDecorationLine: "underline",
              textDecorationColor: color,
              textDecorationThickness: "1px",
              bgcolor: color + "16",
            }}
          >
            {children}
          </Box>
        );
      },
    },
  ]);

  const getNewInlineFuncSet = () => {
    let inlineFuncSet = [];
    for (let block of editorState.getCurrentContent().getBlocksAsArray()) {
      const charList = block.getText().split("");
      let index = 0;
      while (index < charList.length) {
        if (charList[index] === "\\") {
          let inlineFunc = "";
          const startIndex = index;
          while (!isLegalReservedWord(inlineFunc) && index < charList.length) {
            inlineFunc += charList[index];
            index++;
          }
          const endIndex = index;
          if (isLegalReservedWord(inlineFunc)) {
            inlineFuncSet.push({
              uuid: v4(),
              inlineFunc: inlineFunc,
              color: inlineFuncColorOptions[0],
              globalSet: [],
              blockKey: block.getKey(),
              start: startIndex,
              end: endIndex,
            });
          }
        } else {
          index++;
        }
      }
    }
    return inlineFuncSet;
  };

  const bindNewInlineFuncSet = (inlineFuncSet) => {
    let tmpEditorState = editorState;
    const originalSelectionState = editorState.getSelection();
    inlineFuncSet.forEach((inlineFunc) => {
      const selectionState = SelectionState.createEmpty(
        inlineFunc.blockKey
      ).merge({
        anchorOffset: inlineFunc.start,
        focusKey: inlineFunc.blockKey,
        focusOffset: inlineFunc.end,
      });
      const tmpContentStateWithNewEntity = tmpEditorState
        .getCurrentContent()
        .createEntity("inlineFunc", "Mutable", {
          uuid: inlineFunc.uuid,
          inlineFunc: inlineFunc.inlineFunc,
          globalSet: inlineFunc.globalSet,
          color: inlineFunc.color,
        });
      const lastAddedEntityKey =
        tmpContentStateWithNewEntity.getLastCreatedEntityKey();
      const tmpContentStateWithEntityApplication = Modifier.applyEntity(
        tmpContentStateWithNewEntity,
        selectionState,
        lastAddedEntityKey
      );
      tmpEditorState = EditorState.push(
        tmpEditorState,
        tmpContentStateWithEntityApplication,
        "apply-entity"
      );
    });
    setEditorState(
      EditorState.forceSelection(tmpEditorState, originalSelectionState)
    );
  };

  const getEntityByInlineFuncUuid = (uuid) => {
    const currentContent = editorState.getCurrentContent();
    let entity = {};
    for (let block of currentContent.getBlocksAsArray()) {
      for (let characterMetadata of block.getCharacterList()) {
        const entityKey = characterMetadata.getEntity();
        if (
          entityKey &&
          currentContent.getEntity(entityKey).data.uuid === uuid
        ) {
          entity = {
            entityKey: entityKey,
            ...currentContent.getEntity(entityKey).data,
          };
          break;
        }
      }
      if (entity.entityKey) break;
    }
    return entity;
  };

  const getSelectionByInlineFuncUuid = (uuid) => {
    const currentContent = editorState.getCurrentContent();
    let startBlockKey = "";
    let endBlockKey = "";
    let start = -1;
    let end = -1;

    for (let block of currentContent.getBlocksAsArray()) {
      let index = 0;
      for (let characterMetadata of block.getCharacterList()) {
        const entityKey = characterMetadata.getEntity();
        if (
          entityKey &&
          currentContent.getEntity(entityKey).data.uuid === uuid
        ) {
          if (!startBlockKey) {
            start = index;
            startBlockKey = block.getKey();
          }
          endBlockKey = block.getKey();
          end = index;
        } else {
          if (startBlockKey) {
            break;
          }
        }
        index++;
      }
    }
    end++;

    if (start !== -1) {
      return SelectionState.createEmpty(startBlockKey).merge({
        anchorOffset: start,
        focusKey: endBlockKey,
        focusOffset: end,
      });
    } else {
      return null;
    }
  };

  const getContentBySelection = (selection) => {
    const currentContent = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    let result = "";
    if (startKey === endKey && startOffset !== endOffset) {
      result = currentContent
        .getBlockForKey(startKey)
        .getText()
        .slice(startOffset, endOffset);
    } else if (startKey !== endKey) {
      let injectKey = startKey;
      while (injectKey !== currentContent.getKeyAfter(endKey)) {
        const currentBlockText = currentContent
          .getBlockForKey(injectKey)
          .getText();
        const injectText =
          injectKey === startKey
            ? currentBlockText.slice(startOffset) + "\n"
            : injectKey === endKey
            ? currentBlockText.slice(0, endOffset)
            : currentBlockText + "\n";
        injectKey = currentContent.getKeyAfter(injectKey);
        result += injectText;
      }
    }
    return result;
  };

  const updateEntity = (uuid, newData) => {
    if (isComputing) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "正在计算中，请稍等",
        })
      );
    } else {
      const currentContent = editorState.getCurrentContent();
      const selectionState = getSelectionByInlineFuncUuid(uuid);
      const targetAddedEntityKey = getEntityByInlineFuncUuid(uuid).entityKey;
      const tmpContentStateWithNewEntity = currentContent.replaceEntityData(
        targetAddedEntityKey,
        newData
      );
      const tmpContentStateWithEntityApplication = Modifier.applyEntity(
        tmpContentStateWithNewEntity,
        selectionState,
        targetAddedEntityKey
      );
      setEditorState(
        EditorState.push(
          editorState,
          tmpContentStateWithEntityApplication,
          "apply-entity"
        )
      );
    }
  };

  const selectInlineFunc = (uuid) => {
    if (isComputing) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "正在计算中，请稍等",
        })
      );
    } else {
      const selectionState = getSelectionByInlineFuncUuid(uuid);
      if (selectionState) {
        setComputingInlineFunc(getEntityByInlineFuncUuid(uuid));
        setInlineFuncSelectionContent(getContentBySelection(selectionState));
        setEditorState(EditorState.forceSelection(editorState, selectionState));
      } else {
        dispatch(
          setSnackbar({
            type: "error",
            message: "无法找到该InlinFunc",
          })
        );
      }
      setShowExtendedTerminalFlag(true);
    }
  };

  const selectNothing = () => {
    setComputingInlineFunc({});
    setInlineFuncSelectionContent("");
    setShowExtendedTerminalFlag(false);
  };

  const updateInlineFuncContent = (uuid, newContent) => {
    if (isComputing) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "正在计算中，请稍等",
        })
      );
    } else {
      const targetEntity = getEntityByInlineFuncUuid(uuid);
      if (targetEntity.entityKey) {
        const currentContent = editorState.getCurrentContent();
        const selectionState = getSelectionByInlineFuncUuid(uuid);
        if (selectionState) {
          const newContentState = Modifier.replaceText(
            currentContent,
            selectionState,
            newContent,
            null,
            targetEntity.entityKey
          );
          setEditorState(
            EditorState.push(editorState, newContentState, "insert-fragment")
          );
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: "无法找到该InlinFunc",
            })
          );
        }
      } else {
        dispatch(
          setSnackbar({
            type: "error",
            message: "无法找到该InlinFunc",
          })
        );
      }
      setShowExtendedTerminalFlag(false);
    }
  };

  const computeInlineFunc = async (uuid) => {
    if (isComputing) {
      dispatch(
        setSnackbar({
          type: "error",
          message: "正在计算中，请稍等",
        })
      );
    } else {
      setIsComputing(uuid);
      const targetEntity = getEntityByInlineFuncUuid(uuid);
      if (targetEntity.entityKey) {
        const computeReservedWordResult = await computeReservedWord(
          targetEntity.inlineFunc,
          targetEntity.uuid,
          targetEntity.globalSet
        );
        if (computeReservedWordResult.status === "success") {
          const currentContent = editorState.getCurrentContent();
          const selectionState = getSelectionByInlineFuncUuid(uuid);
          if (selectionState) {
            const newContentState = Modifier.replaceText(
              currentContent,
              selectionState,
              computeReservedWordResult.data,
              null,
              targetEntity.entityKey
            );
            setEditorState(
              EditorState.push(editorState, newContentState, "insert-fragment")
            );
          } else {
            dispatch(
              setSnackbar({
                type: "error",
                message: "无法找到该InlinFunc",
              })
            );
          }
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: computeReservedWordResult.message,
            })
          );
        }
      } else {
        dispatch(
          setSnackbar({
            type: "error",
            message: "无法找到该InlinFunc",
          })
        );
      }
      setIsComputing(null);
    }
  };

  useEffect(() => {
    if (novel.novelId) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(novel.draftRaw), decorator)
      );
    }
  }, [novel.novelId]);

  useEffect(() => {
    if (novel.novelId && editorState) {
      // 获取常用变量
      const currentContent = editorState.getCurrentContent();

      // 更新inlineFunc
      const tmpPlainTextLength = currentContent.getPlainText().length;
      const newInlineFuncSet = getNewInlineFuncSet();
      if (newInlineFuncSet.length && plainTextLength !== tmpPlainTextLength) {
        bindNewInlineFuncSet(newInlineFuncSet);
      }
      setPlainTextLength(tmpPlainTextLength);

      // 更新inlineFuncSelection
      const tmpSelection = editorState.getSelection();
      if (
        tmpSelection.getStartKey() === tmpSelection.getEndKey() &&
        tmpSelection.getStartOffset() === tmpSelection.getEndOffset()
      ) {
        let selectedEntityKey = currentContent
          .getBlockForKey(tmpSelection.getStartKey())
          .getEntityAt(tmpSelection.getStartOffset());
        if (selectedEntityKey) {
          selectInlineFunc(
            currentContent.getEntity(selectedEntityKey).data.uuid
          );
        } else {
          selectNothing();
        }
      }

      // 将Editor中的内容更新至redux
      dispatch(
        setNovel({
          ...novel,
          draftRaw: convertToRaw(editorState.getCurrentContent()),
        })
      );
    }
  }, [editorState]);

  // useEffect(() => {
  //   console.log(JSON.stringify(novel.draftRaw));
  // }, [novel.draftRaw]);

  return (
    <Box display={"flex"}>
      <EditorDrawer
        isComputing={isComputing}
        updateEntity={updateEntity}
        selectInlineFunc={selectInlineFunc}
        computeInlineFunc={computeInlineFunc}
      />
      <Container
        maxWidth="lg"
        sx={{ position: "relative", minHeight: "calc(100vh - 64px)" }}
      >
        <Box
          height={"calc(100vh - 64px - 53px)"}
          py={1}
          overflow={"auto"}
          bgcolor={"#faf5ea"}
        >
          {editorState && (
            <Editor
              editorState={editorState}
              onChange={(editorState) => {
                setEditorState(editorState);
              }}
            />
          )}
        </Box>
        <Terminal
          showExtendedTerminalFlag={showExtendedTerminalFlag}
          setShowExtendedTerminalFlag={setShowExtendedTerminalFlag}
          computingInlineFunc={computingInlineFunc}
          inlineFuncSelectionContent={inlineFuncSelectionContent}
          updateInlineFuncContent={updateInlineFuncContent}
          updateEntity={updateEntity}
          isComputing={isComputing}
          computeInlineFunc={computeInlineFunc}
        />
      </Container>
    </Box>
  );
}
