import { Container } from "@mui/material";
import AppLayout from "../components/layout/AppLayout";
import PersonalInfoBar from "../components/citizen/PersonalInfoBar";
import PersonalNav from "../components/citizen/PersonalNav";
import { useSelector } from "react-redux";

export default function CitizenView() {
  // redux变量区
  const userInfo = useSelector((state) => state.userInfo.value);

  return (
    <AppLayout>
      <Container maxWidth="xl" sx={{ pt: 4 }}>
        <PersonalInfoBar user={userInfo} />
        <PersonalNav user={userInfo} />
      </Container>
    </AppLayout>
  );
}
