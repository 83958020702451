import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setRoutesList } from "./plugin/store/slices/routesListSlice";
import CitizensView from "./views/CitizensView";
import CitizenView from "./views/CitizenView";
import LoginView from "./views/LoginView";
import DesktopView from "./views/DesktopView";
import NovelDetailView from "./views/NovelDetailView";
import EditorView from "./views/EditorView";
import _axios from "./plugin/axios";
import { setUserInfo } from "./plugin/store/slices/userInfoSlice";
import { setSnackbar } from "./plugin/store/slices/snackbarSlice";
import { AccountBox, HistoryEdu, SignLanguage } from "@mui/icons-material";

function App() {
  const token = useSelector((state) => state.token);
  const routesList = useSelector((state) => state.routesList);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const userInfoInit = () => {
    if (token || token.value !== "") {
      _axios
        .get("/rbac/user")
        .then((resp) => {
          if (resp.data.code === "00") {
            dispatch(setUserInfo({ ...resp.data.data }));
          } else {
            dispatch(
              setSnackbar({
                type: "error",
                message: resp.data.message,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackbar({
              type: "error",
              message: error.message,
            })
          );
        });
    }
  };

  const routesListInit = () => {
    if (!token || token.value === "") {
      dispatch(
        setRoutesList([
          { path: "/login", element: <LoginView />, title: "登录" },
          {
            path: "*",
            loader: () => {
              return redirect("/login");
            },
          },
        ])
      );
    } else {
      dispatch(
        setRoutesList([
          {
            path: "/citizens",
            element: <CitizensView />,
            title: "社区",
            icon: <SignLanguage />,
          },
          {
            path: "/desktop",
            element: <DesktopView />,
            title: "思考与写作",
            icon: <HistoryEdu />,
          },
          {
            path: "/citizen",
            element: <CitizenView />,
            title: "我的空间",
            icon: <AccountBox />,
          },
          {
            path: "/novel/:novelId",
            element: <NovelDetailView />,
            title: "文章详情",
          },
          {
            path: "/editor/:novelId",
            element: <EditorView />,
            title: "编辑文章",
          },
          {
            path: "*",
            loader: () => {
              return redirect("/citizens");
            },
          },
        ])
      );
    }
  };

  useEffect(() => {
    routesListInit();
    userInfoInit();
  }, [token]);

  useEffect(() => {
    setLoading(false);
  }, [routesList]);

  return (
    !loading && (
      <RouterProvider router={createBrowserRouter(routesList.value)} />
    )
  );
}

export default App;
