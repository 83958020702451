import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Icon from "@mui/icons-material";
import { setToken } from "../../plugin/store/slices/tokenSlice";
import { setSnackbar } from "../../plugin/store/slices/snackbarSlice";
import _axios from "../../plugin/axios";

export default function LoginCard() {
  // redux变量区
  const dispatch = useDispatch();

  // state变量区
  const [username, setUsername] = useState({ value: "" });
  const [password, setPassword] = useState({ value: "", visiable: false });
  const [registerDialogState, setRegisterDialogState] = useState(false);

  const fillUsername = (value) => {
    setUsername({
      ...username,
      value,
      errorFlag: false,
      errorMessage: "",
    });
  };

  const fillPassword = (value) => {
    if (value === "") {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage: "密码不能为空",
      });
    } else {
      setPassword({
        ...password,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const changePasswordVisibility = () => {
    setPassword({
      ...password,
      visiable: !password.visiable,
    });
  };

  const register = () => {
    setRegisterDialogState(true);
  };

  const finishRegister = () => {
    setRegisterDialogState(false);
  };

  const reset = () => {
    setUsername({
      value: "",
      errorFlag: false,
      errorMessage: "",
    });
    setPassword({
      value: "",
      errorFlag: false,
      errorMessage: "",
      visiable: false,
    });
  };

  const login = () => {
    const postObj = {
      username: username.value,
      password: password.value,
    };
    _axios
      .post("/rbac/login", postObj)
      .then((resp) => {
        if (resp.data.code === "00") {
          dispatch(setToken(resp.data.data));
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "error",
            message: error.message,
          })
        );
      });
  };

  const jumpToICP = () => {
    window.location.href = "https://beian.miit.gov.cn";
  };

  return (
    <Box
      height={"100vh"}
      bgcolor={"#e6d1a4"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        px: 2,
      }}
    >
      <Box>
        <CardMedia
          component="img"
          image={require("../../assets/logo.png")}
          sx={{
            mx: "auto",
            mt: 8,
            width: "260px",
          }}
        />
        <CardContent>
          <TextField
            label="用户名"
            value={username.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={username.errorFlag}
            helperText={username.errorMessage}
            onInput={(event) => {
              fillUsername(event.target.value);
            }}
          />

          <FormControl
            variant="standard"
            margin="normal"
            fullWidth
            error={password.errorFlag}
          >
            <InputLabel>密码</InputLabel>
            <Input
              type={password.visiable ? "text" : "password"}
              value={password.value}
              onInput={(event) => {
                fillPassword(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      changePasswordVisibility();
                    }}
                  >
                    {password.visiable ? (
                      <Icon.VisibilityOff />
                    ) : (
                      <Icon.Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{password.errorMessage}</FormHelperText>
          </FormControl>
        </CardContent>

        <CardActions>
          <Button
            onClick={() => {
              register();
            }}
          >
            注册
          </Button>
          <Box sx={{ flex: 1 }} />
          <Button
            disabled={username.value === "" && password.value === ""}
            color="inherit"
            onClick={() => {
              reset();
            }}
          >
            重置
          </Button>
          <Button
            disabled={
              username.value === "" ||
              password.value === "" ||
              username.errorFlag ||
              password.errorFlag
            }
            onClick={() => {
              login();
            }}
          >
            登录
          </Button>
        </CardActions>
      </Box>
      <Box>
        <Typography
          variant="caption"
          component="div"
          color="beige"
          sx={{
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          {`Copyright © 2023-${new Date().getFullYear()} 柏拉图的注目`}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          color="beige"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            jumpToICP();
          }}
        >
          沪ICP备2023024934号-1
        </Typography>
      </Box>
      {registerDialogState && (
        <RegisterDialog
          dialogState={registerDialogState}
          finishEdition={finishRegister}
        />
      )}
    </Box>
  );
}

const RegisterDialog = ({ dialogState, finishEdition }) => {
  // redux变量区
  const dispatch = useDispatch();

  // state变量区
  const [passwordValidator] = useState(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$%^&*-_+=]{8,20}$/
  );
  const [username, setUsername] = useState({ value: "" });
  const [password, setPassword] = useState({ value: "" });
  const [passwordChecker, setPasswordChecker] = useState({ value: "" });

  const fillUsername = (value) => {
    if (value === "") {
      setUsername({
        ...username,
        value: value,
        errorFlag: true,
        errorMessage: "用户名不能为空",
      });
    } else {
      setUsername({
        ...username,
        value: value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const fillPassword = (value) => {
    if (value === "") {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage: "密码不能为空",
      });
    } else if (!value.match(passwordValidator)) {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage:
          "密码应当是字母和数字的组合，并且长度在8-20位（支持特殊标点!@#$%^&*-_+=）",
      });
    } else {
      setPassword({
        ...password,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const fillPasswordChecker = (value) => {
    if (value === "") {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage: "重复密码不能为空",
      });
    } else if (value !== password.value) {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage: "与密码不同",
      });
    } else if (!value.match(passwordValidator)) {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage:
          "密码应当是字母和数字的组合，并且长度在8-20位（支持特殊标点!@#$%^&*-_+=）",
      });
    } else {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const changePasswordVisibility = () => {
    setPassword({
      ...password,
      visiable: !password.visiable,
    });
    setPasswordChecker({
      ...passwordChecker,
      visiable: !passwordChecker.visiable,
    });
  };

  const login = () => {
    const postObj = {
      username: username.value,
      password: password.value,
    };
    _axios
      .post("/rbac/login", postObj)
      .then((resp) => {
        if (resp.data.code === "00") {
          dispatch(setToken(resp.data.data));
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "error",
            message: error.message,
          })
        );
      });
  };

  const submintEdition = () => {
    let postObj = {
      username: username.value,
      password: password.value,
      passwordChecker: passwordChecker.value,
      gender: 0,
      phoneNumber: "",
      email: "",
      job: "无",
      location: "中国",
      selfIntroduction: "这个人很懒，什么也没有留下～",
    };
    _axios
      .post("/rbac/register", postObj)
      .then((resp) => {
        if (resp.data.code === "00") {
          dispatch(
            setSnackbar({
              type: "success",
              message: resp.data.message,
            })
          );
          login();
        } else {
          dispatch(
            setSnackbar({
              type: "error",
              message: resp.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            type: "error",
            message: error.message,
          })
        );
      });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { bgcolor: "#faf5ea" },
      }}
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>新用户注册</DialogTitle>
      <IconButton
        color="inherit"
        onClick={() => {
          finishEdition();
        }}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Icon.Close />
      </IconButton>

      <DialogContent>
        <Grid container columnSpacing={2} spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="用户名"
              value={username.value}
              variant="standard"
              margin="normal"
              fullWidth
              error={username.errorFlag}
              helperText={username.errorMessage}
              onInput={(event) => {
                fillUsername(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="standard"
              margin="normal"
              fullWidth
              error={password.errorFlag}
            >
              <InputLabel>密码</InputLabel>
              <Input
                type={password.visiable ? "text" : "password"}
                value={password.value}
                onInput={(event) => {
                  fillPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        changePasswordVisibility();
                      }}
                    >
                      {password.visiable ? (
                        <Icon.VisibilityOff />
                      ) : (
                        <Icon.Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{password.errorMessage}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="standard"
              margin="normal"
              fullWidth
              error={passwordChecker.errorFlag}
            >
              <InputLabel>重复密码</InputLabel>
              <Input
                type={passwordChecker.visiable ? "text" : "password"}
                value={passwordChecker.value}
                onInput={(event) => {
                  fillPasswordChecker(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        changePasswordVisibility();
                      }}
                    >
                      {passwordChecker.visiable ? (
                        <Icon.VisibilityOff />
                      ) : (
                        <Icon.Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{passwordChecker.errorMessage}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={
            username.value === "" ||
            password.value === "" ||
            passwordChecker.value === "" ||
            username.errorFlag ||
            password.errorFlag ||
            passwordChecker.errorFlag
          }
          onClick={() => {
            submintEdition();
          }}
        >
          注册
        </Button>
      </DialogActions>
    </Dialog>
  );
};
