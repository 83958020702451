import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function AppSnackbar() {
  // redux变量区
  const snackbar = useSelector((state) => state.snackbar);

  // state变量区
  const [sanckbarState, setSanckbarState] = useState(false);

  // 监听方法区
  // 监听redux变量snackbar的变化情况，并显示Snackbar组件
  useEffect(() => {
    if (snackbar.type && snackbar.message) openSnackbar();
  }, [snackbar]);

  // 显示snackbar
  const openSnackbar = () => {
    setSanckbarState(true);
  };

  // 关闭snackbar
  const closeSnackbar = () => {
    setSanckbarState(false);
  };

  // 渲染区
  return (
    <Snackbar
      open={sanckbarState}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={closeSnackbar}
    >
      <Alert severity={snackbar.type} sx={{ width: "100%" }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}
